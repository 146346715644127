import { fetchPostAPI } from '../../../general/BuddyFuncs'
import { stateName } from './forgotPasswordReducer'
import { success } from '../../redux/configureStore'

export const resetAccountPassword = accountData => {
  return {
    type: `${stateName}_REQUEST`,
    payload: fetchPostAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/send-token`,
      method: 'POST',
      formData: accountData,
    }),
  }
}

export const resetAccountPrefill = email => {
  return {
    type: `${stateName}_PREFILL${success}`,
    payload: email,
  }
}
