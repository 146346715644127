import React from 'react'
import { overlayToggleOpen } from '../../overlay/api'
import Button from '@mui/material/Button'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const ExportCRMBtn = () => {
  const handleExportCRM = e => {
    overlayToggleOpen(true, 'export-crm', 'Export lijst bedrijven en contacten')
  }

  return (
    <Button variant="text" className="exportbtn" onClick={handleExportCRM}>
      <FileDownloadOutlinedIcon /> &nbsp;Export lijst
    </Button>
  )
}

export default ExportCRMBtn
