import { stateName } from './overlayReducer'
import { store } from '../../redux/configureStore'
import { fetchAPI } from '../../../general/BuddyFuncs'

export const toggleOpenAction = isOpen => {
  return { type: `${stateName}_TOGGLE_OPEN`, payload: isOpen }
}

export const setTitleAction = (title = '') => {
  return { type: `${stateName}_SET_TITLE`, payload: title }
}

export const setFormTypeAction = (formType = '') => {
  return { type: `${stateName}_SET_FORMTYPE`, payload: formType }
}

export const setPrefillAction = (prefill = {}) => {
  return { type: `${stateName}_SET_PREFILL`, payload: prefill }
}

export const setSelectOptionsAction = () => {
  const token = store.getState().loginReducer.data.bearerToken
  return {
    type: `${stateName}_SET_SELECT_OPTIONS`,
    payload: fetchAPI(
      `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/options`,
      {
        Authorization: token,
      },
    ),
  }
}
