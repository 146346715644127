import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import MeetingRoomRoundedIcon from '@material-ui/icons/MeetingRoomRounded'
import { fetchAPI } from '../../general/BuddyFuncs'
import { loginReset } from '../components/login/api'
import { useSelector } from 'react-redux'

const MainHeader = props => {
  const currentLocation = useLocation()
  const userData = useSelector(state => state.loginReducer.data)

  const handleLogout = e => {
    e.preventDefault()

    document.getElementById('root').style.display = 'none'
    fetchAPI(`${process.env.REACT_APP_PUBLIC_URL}api/user/logout`).then(r => {
      loginReset()
      window.localStorage.clear()
      location.reload()
    })
  }

  const checkAdminLevel = item => {
    if (item.adminLevel !== undefined) {
      //has user restrictions
      if (userData.profile) {
        //user is logged in so check
        const id = parseInt(userData.profile.roles_id)

        return id <= item.adminLevel && id !== 0
      } else {
        //no user login so block automaticly
        return false
      }
    }

    if (item.title === 'CRM') {
      if (userData.profile) {
        //user is logged in so check
        return userData.profile.crm_access ?? false
      } else {
        //no user login so block automaticly
        return false
      }
    }

    return true
  }

  return (
    <header className={'mainHeader' + (!userData.profile ? ' onboarding' : '')}>
      <div className={'noLogoIMG'}></div>
      <nav>
        <ul>
          {props.menuItems
            ? props.menuItems.map((item, index) => {
                if (checkAdminLevel(item)) {
                  return (
                    <li
                      key={`navItem${item.title}${index}`}
                      className={
                        item.link === currentLocation.pathname
                          ? 'isActive'
                          : null
                      }
                    >
                      <Link to={item.link}>
                        <span className="icon">{item.icon}</span>
                        <span className="title">{item.title}</span>
                      </Link>
                    </li>
                  )
                }

                return null
              })
            : null}

          <li>
            <a href="#" onClick={e => handleLogout(e)}>
              <span className="icon">
                <MeetingRoomRoundedIcon />
              </span>
              <span className="title">Log uit</span>
            </a>
          </li>
        </ul>
      </nav>
    </header>
  )
}

export default MainHeader
