import React from 'react'
import {
  addLeadingZerosToTime,
  addMultipleTimeDiffs,
} from '../../../../general/BuddyFuncs'

const Totals = props => {
  const calcTotalHours = currentUser => {
    if (props.entries) {
      const time = addMultipleTimeDiffs(props.entries, currentUser)

      return addLeadingZerosToTime(`${time.hours}:${time.minutes}`)
    }

    return null
  }

  return (
    <div className="totals card">
      <div className="total">
        <h4 className="small">Mijn totaal</h4>
        <p>{calcTotalHours(1)}</p>
      </div>
      <div className="total">
        <h4 className="small">Totaal</h4>
        <p>{calcTotalHours(0)}</p>
      </div>
    </div>
  )
}

export default Totals
