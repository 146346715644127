import { fetchPostAPI } from '../../../general/BuddyFuncs'
import { reset } from '../../redux/configureStore'
import { stateName } from './createPasswordReducer'

export const setNewPassword = passwordData => {
  return {
    type: `${stateName}_POST_PASSWORD`,
    payload: fetchPostAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/set-password`,
      method: 'POST',
      formData: passwordData,
    }),
  }
}

export const resetPasswordReducer = () => {
  return {
    type: `${stateName}_CLEAR_PASSWORD_REDUCER${reset}`,
    payload: false,
  }
}
