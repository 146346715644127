import React from 'react'
import { overlayToggleOpen } from '../../overlay/api'
import Button from '@mui/material/Button'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined'

const Exportbtn = () => {
  const handleExportTimesheets = e => {
    overlayToggleOpen(true, 'export', 'Export timesheets')
  }

  return (
    <Button
      variant="text"
      className="exportbtn"
      onClick={handleExportTimesheets}
    >
      <FileDownloadOutlinedIcon /> &nbsp;Export timesheets
    </Button>
  )
}

export default Exportbtn
