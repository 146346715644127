import { fetchPostJsonAPI } from '../../../general/BuddyFuncs'
import { store } from '../../redux/configureStore'
import {
  fetchNewEmployeesAction,
  // fetchRolesAction,
} from './employeePageActions'

export const employeeFetchAll = () => {
  store.dispatch(fetchNewEmployeesAction())
}

// export const employeeFetchRoles = () => {
//   store.dispatch(fetchRolesAction());
// };

export const employeeCheckIfFetched = () => {
  const state = store.getState()
  const employeePageReducer = state.employeePageReducer

  if (!employeePageReducer.fetched && !employeePageReducer.fetching) {
    employeeFetchAll()
  }
}

export const employeeUpdateStatus = (users_id, status) => {
  const token = store.getState().loginReducer.data.bearerToken
  return fetchPostJsonAPI({
    url: `${process.env.REACT_APP_PUBLIC_URL}api/user/user-record`,
    method: 'PUT',
    headers: {
      Authorization: token,
    },
    json: {
      users_id: users_id,
      active: status,
    },
  })
}
