import { fetchAPI } from '../../general/BuddyFuncs'
import {
  loginGetUserData,
  loginReset,
  loginSetTokenChecked,
} from '../components/login/api'

export const heartbeat = () => {
  const token = sessionStorage.getItem('token')

  //check for alive
  return fetchAPI(`${process.env.REACT_APP_PUBLIC_URL}api/user/heartbeat`, {
    Authorization: token,
  }).then(r => {
    if (!r.alive) {
      //remove token
      loginReset()
    }

    return r
  })
}

export const checkValidityOfToken = () => {
  const token = sessionStorage.getItem('token')

  if (token) {
    return fetchAPI(`${process.env.REACT_APP_PUBLIC_URL}api/user/heartbeat`, {
      Authorization: token,
    }).then(r => {
      if (r.alive) {
        //is alive
        //get user data as normal
        loginGetUserData()
      } else {
        loginSetTokenChecked(true)
      }

      return r.alive
    })
  } else {
    loginSetTokenChecked(true)
  }
}
