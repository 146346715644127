import { success } from '../../redux/configureStore'

export const stateName = 'FORGOT_PASSWORD'

const initState = {
  posted: false,
  prefillLogin: '',
  data: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case `${stateName}_REQUEST${success}`:
      return {
        ...state,
        posted: true,
        data: action.payload,
      }
    case `${stateName}_PREFILL${success}`:
      return {
        ...state,
        prefillLogin: action.payload,
      }
    default:
      return state
  }
}
