import React from 'react'
import {
  getNumberOfWeek,
  writeDate,
  getDateOfWeek,
  addDays,
  weeksBetween,
} from '../../../general/BuddyFuncs'
import Week from './components/Week'

const TimesheetWeek = props => {
  const content = props.content
  const fromTo = props.fromTo

  //calulate number of weeks
  //per week create 5 days and check if they have an entry that matches
  const calcWeekCount = () => {
    const firstWeek = getNumberOfWeek(fromTo.startDate)
    const lastWeek = getNumberOfWeek(fromTo.endDate)
    const firstWeekdate = getDateOfWeek(
      firstWeek,
      new Date(fromTo.startDate).getFullYear(),
    )
    const lastWeekdate = addDays(
      getDateOfWeek(lastWeek, new Date(fromTo.endDate).getFullYear()),
      6,
    )

    const weeksCount = weeksBetween(firstWeekdate, lastWeekdate) + 1

    return {
      firstWeekdate,
      lastWeekdate,
      weeksCount,
    }
  }

  const calcWeeks = () => {
    const weeksData = calcWeekCount()
    const weeks = []

    for (let i = 0; i < weeksData.weeksCount; i++) {
      weeks.push({
        startDate: addDays(weeksData.firstWeekdate, i * 7),
        endDate: addDays(weeksData.firstWeekdate, i * 7 + 4),
      })
    }

    return weeks
  }

  const createWeeks = () => {
    //calulate number of weeks
    return calcWeeks().map((weekData, index) => {
      //create for each week a component
      let weekArray = [false, false, false, false, false]
      const weekNumber = getNumberOfWeek(weekData.startDate)

      //check all the options if there is match
      if (content.dayEntries) {
        content.dayEntries.forEach(dayEntry => {
          const entryWeekNumber = getNumberOfWeek(dayEntry.date)

          //if this entry fist in this week add it to the array
          if (entryWeekNumber === weekNumber) {
            const entryWeekDay = new Date(dayEntry.date).getDay() - 1
            weekArray[entryWeekDay] = dayEntry
          }
        })
      }

      //set the false values in the weekarray to the correct dates
      weekArray = weekArray.map((day, index) => {
        if (!day) {
          //day is false so add date
          const newDate = addDays(weekData.startDate, index)

          return {
            date: writeDate(newDate, '-', 'date'),
            startTime: '08:00',
            endTime: '08:01',
          }
        }

        return day
      })

      return (
        <Week
          key={`weekEntry${index}${weekNumber}`}
          weekNumber={weekNumber}
          entries={weekArray}
          headers={content.headers}
        />
      )
    })
  }

  return (
    <div className="timesheetWeek">
      {content.dayEntries ? createWeeks() : null}
    </div>
  )
}

export default TimesheetWeek
