import React from 'react'
import Employee from './components/Employee'
import { useSelector } from 'react-redux'
import { employeeCheckIfFetched } from './api'
import { overlayToggleOpen } from '../overlay/api'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'

const EmployeePage = () => {
  const employeesData = useSelector(state => state.employeePageReducer.data)
  const userData = useSelector(state => state.loginReducer.data.profile)

  employeeCheckIfFetched()

  const handleAddEmployee = e => {
    e.preventDefault()

    overlayToggleOpen(true, 'employee', 'Werknemer toevoegen')
  }

  const handleAddOrganization = e => {
    e.preventDefault()

    overlayToggleOpen(true, 'organization', 'Organisatie toevoegen')
  }

  return (
    <div className="employeePage">
      <div className="employeeList">
        {employeesData.employees ? (
          <table>
            <thead>
              <tr>
                <td>Medewerker</td>
                <td>Organisatie</td>
                <td>E-mail</td>
                <td>GSM</td>
                <td>Toegang</td>
              </tr>
            </thead>
            <tbody>
              {employeesData.employees.map(employee => {
                return (
                  <Employee
                    key={`employee${employee.users_id}`}
                    {...employee}
                    userType={userData ? userData.roles_id : false}
                  />
                )
              })}
            </tbody>
          </table>
        ) : null}
      </div>
      {userData.roles_id === '0' || '1' || '2' ? (
        <Button
          variant="contained"
          className="addOrganizations"
          onClick={handleAddOrganization}
        >
          <AddIcon /> &nbsp;Organisatie toevoegen
        </Button>
      ) : null}
      <Button
        variant="contained"
        className="addEmployee"
        onClick={handleAddEmployee}
      >
        <AddIcon /> &nbsp;Medewerker toevoegen
      </Button>
    </div>
  )
}

export default EmployeePage
