import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { CompaniesTable } from './tables/CompaniesTable'
import { ContactsTable } from './tables/ContactsTable'
import { Route, Switch, Link, useRouteMatch, Redirect } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CompanyDetail } from './detail'
import { CreateCompany } from './create/CreateCompany'
import { CreateContact } from './create/CreateContact'
import { Button, Grid } from '@mui/material'
import AddIcon from '@material-ui/icons/Add'
import ExportCRMbtn from './export/ExportCRMBtn'

export function Crm() {
  const { t } = useTranslation()
  const routeMatch = useRouteMatch([
    //'/crm/companies/add',
    //'/crm/contacts/add',
    '/crm/companies',
    '/crm/contacts',
  ])
  const currentTab = routeMatch?.path

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Tabs value={currentTab} style={{ marginBottom: '16px' }}>
            <Tab
              label={t('crm.companies')}
              component={Link}
              value="/crm/companies"
              to="/crm/companies"
            />
            <Tab
              label={t('crm.contacts')}
              component={Link}
              value="/crm/contacts"
              to="/crm/contacts"
            />
          </Tabs>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <ExportCRMbtn />
          <Button
            variant="contained"
            component={Link}
            to="/crm/companies/add"
            style={{ marginLeft: '1.6em' }}
          >
            <AddIcon fontSize="large" />
            &nbsp;
            {t('general.bedrijfToevoegen')}
          </Button>
        </Grid>
      </Grid>

      <Switch>
        <Route path="/crm/companies/add" component={CreateCompany} />
        <Route
          path="/crm/companies/:companyId/add-contact"
          component={CreateContact}
        />
        <Route
          path="/crm/companies/:companyId"
          render={props => (
            <CompanyDetail key={props.match.params.companyId || 'empty'} />
          )}
        />
        <Route path="/crm/companies" component={CompaniesTable} />
        <Route path="/crm/contacts" component={ContactsTable} />
        <Redirect from="/" to="/crm/companies" />
      </Switch>
    </div>
  )
}
