import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import InputMat from '../../__core/components/InputMat'
import Button from '@mui/material/Button'
import { newPasswordRequest } from './api'
import { useSelector } from 'react-redux'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

export const CreatePassword = () => {
  const [validation, setValidation] = useState({})
  const history = useHistory()
  const query = useQuery()
  const passToken = query.get('t')

  const { tokenInvalid, passwordUpdated } = useSelector(state => {
    return {
      tokenInvalid: state.passwordRestReducer.data.invalid,
      passwordUpdated: state.passwordRestReducer.data.status,
    }
  })

  // Redirect to login if token is invalid
  useEffect(() => {
    if (!tokenInvalid) {
      return
    }
    history.push(`/`)
  }, [tokenInvalid])

  // Redirect to login if update success
  useEffect(() => {
    if (!passwordUpdated) {
      return
    }
    history.push(`/`)
  }, [passwordUpdated])

  const validate = data => {
    const errors = {}
    const originPass = data.password
    const checkPass = data.confirmPassword

    if (originPass !== checkPass) {
      errors.required = true
      errors.password = 'wachtwoorden komen niet overeen.'
    } else if (originPass === '') {
      errors.required = true
      errors.password = ''
    }
    setValidation(errors)
    return !Object.keys(errors).length
  }

  const resetConfirmErrorMessage = () => {
    setValidation('')
  }

  const submitForm = e => {
    e.preventDefault()
    const form = e.currentTarget
    const formData = Object.fromEntries(new FormData(form))

    if (!validate(formData)) return false

    const formDataST = new FormData()
    formDataST.append('token', formData.token)
    formDataST.append('password', formData.password)
    newPasswordRequest(formDataST)
  }

  return (
    <div className="login">
      <form onSubmit={e => submitForm(e)}>
        <InputMat
          id={'password'}
          name={'password'}
          label={'Wachtwoord'}
          type="password"
          error={!!validation.required}
          onChange={resetConfirmErrorMessage}
        />
        <InputMat
          id={'confirmPassword'}
          name={'confirmPassword'}
          label={'Confirmeer wachtwoord'}
          type="password"
          onChange={resetConfirmErrorMessage}
          error={!!validation.required}
          errorText={!!validation.password ? validation.password : ''}
        />
        <input name="token" type="hidden" value={passToken} />
        <Button type="submit" variant="contained">
          Submit
        </Button>
      </form>
    </div>
  )
}
