import './css/index.scss'
import ReactInitClass from './js/react/index'
import axios from 'axios'

axios.defaults.baseURL =
  process.env.REACT_APP_PUBLIC_URL !== '/'
    ? process.env.REACT_APP_PUBLIC_URL.slice(0, -1)
    : process.env.REACT_APP_PUBLIC_URL

const reactClass = new ReactInitClass({
  reactRoot: '#root',
})

reactClass.init()
