import React from 'react'
import {
  fetchCompanies,
  searchContacts,
  selectContacts,
  selectFilterdContacts,
} from '../crmSlice'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TextField, Button } from '@mui/material'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export function ContactsTable() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const contacts = useSelector(selectContacts)
  const filterdContacts = useSelector(selectFilterdContacts)
  const [searchValueField, setSearchValueField] = useState(null)

  const changeSearch = e => {
    setSearchValueField(e.target.value)
  }

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [dispatch])

  let mapCells = contacts => {
    return contacts.map(row => (
      <TableRow key={row.id}>
        <TableCell key={row.id + '_company'}>
          <strong>
            <Link to={`/crm/companies/${row.crm_bedrijf_id}`}>
              {row?.voornaam + ' ' + row?.naam}
            </Link>
          </strong>
        </TableCell>
        <TableCell key={row.id + '_tel'}>
          {row?.telefoon !== '' ? (
            <PhoneOutlinedIcon
              fontSize="inherit"
              style={{ marginBottom: '-2px', marginRight: '5px' }}
            />
          ) : (
            ''
          )}
          {row?.telefoon}
        </TableCell>
        <TableCell key={row.id + '_email'}>
          {row?.email !== '' ? (
            <EmailOutlinedIcon
              fontSize="inherit"
              style={{ marginBottom: '-2px', marginRight: '5px' }}
            />
          ) : (
            ''
          )}
          {row?.email}
        </TableCell>
        <TableCell key={row.id + '_list'}>
          <span className="list">
            {row.orgs.map(link => {
              return (
                <span key={link.id}>
                  <strong>{link.organisatie}</strong>
                  <br />
                  {link.functie}
                </span>
              )
            })}
          </span>
        </TableCell>
        <TableCell key={row.id + '_detailfiche'} style={{ textAlign: 'right' }}>
          <Link to={`/crm/companies/${row.crm_bedrijf_id}`}>
            <Button variant="outlined">
              <ContactsOutlinedIcon /> &nbsp;&nbsp;DETAILFICHE
            </Button>
          </Link>
        </TableCell>
      </TableRow>
    ))
  }

  return (
    <div className="crmList">
      <TextField
        margin="normal"
        fullWidth
        label={t('crm.search')}
        onChange={e => {
          dispatch(searchContacts(e.target.value))
          changeSearch(e)
        }}
        autoFocus
        style={{ maxWidth: '300px' }}
      />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell key="contact_name" style={{ fontSize: '1.2rem' }}>
              {t('crm.contact.name')}
            </TableCell>
            <TableCell key="contact_phone" style={{ fontSize: '1.2rem' }}>
              {t('crm.contact.phone')}
            </TableCell>
            <TableCell key="contact_email" style={{ fontSize: '1.2rem' }}>
              {t('crm.contact.email')}
            </TableCell>
            <TableCell key="contact_org_list" className="orgList">
              &nbsp;
            </TableCell>
            <TableCell key="contact_detailfiche">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!searchValueField ? (
            filterdContacts.length !== 0 ? (
              mapCells(filterdContacts)
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <div className="crm noEntries">
                    {t('crm.noSearchResults')}
                  </div>
                </TableCell>
              </TableRow>
            )
          ) : (
            mapCells(contacts)
          )}
        </TableBody>
      </Table>
    </div>
  )
}
