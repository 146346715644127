import { store } from '../../redux/configureStore'
import {
  setPrefillAction,
  setTitleAction,
  toggleOpenAction,
  setFormTypeAction,
  setSelectOptionsAction,
} from './overlayActions'

//function resets values on close if no values given
export const overlayToggleOpen = (
  isOpen,
  formType = '',
  title = '',
  prefill = {},
) => {
  //toggle
  store.dispatch(toggleOpenAction(isOpen))

  //set title
  store.dispatch(setTitleAction(title))

  //set formType
  store.dispatch(setFormTypeAction(formType))

  //set prefill
  store.dispatch(setPrefillAction(prefill))
}

export const overlayGetSelectOptions = () => {
  const state = store.getState()
  const selectOptions = state.overlayReducer.selectOptions

  // if (!selectOptions.fetching && !selectOptions.fetched) {
  if (!selectOptions.fetching) {
    store.dispatch(setSelectOptionsAction())
  }
}
