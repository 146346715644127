import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'

export const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  borderRadius: '10px',
}))
