export const stateName = 'TYPESELECTOR'

const initState = {
  selected: {},
  options: [
    /*{
      type: "user",
      index: "1",
      title: "Naam Achternaam",
      icon: "",
    },
    {
      type: "project",
      index: "2",
      title: "Project",
      icon: "",
    },*/
  ],
}

export default (state = initState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case `${stateName}_SET_SELECTED`:
      return {
        ...state,
        selected: action.payload,
      }
    case `${stateName}_SET_OPTIONS`:
      return {
        ...state,
        options: action.payload,
      }
    default:
      return state
  }
}
