import React, { useState } from 'react'
import { fetchGetAPI } from '../../../../general/BuddyFuncs'
import { useSelector } from 'react-redux'
import { overlayToggleOpen } from '../api'
import SelectMat from '../../../__core/components/SelectMat'
import Button from '@mui/material/Button'

const ExportCRMForm = () => {
  const userData = useSelector(state => state.loginReducer.data)
  const organisations = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.organisations || {},
  )
  const companyTypes = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.companyTypes || {},
  )
  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [selectedListType, setSelectedListType] = useState(null)

  const downloadFile = content => {
    const downloadLink = document.createElement('a')
    const blob = new Blob(['\ufeff', content])
    const url = URL.createObjectURL(blob)

    //add hide class
    downloadLink.classList.add('hide')

    //add attributes to link
    const timestampExportFile = +new Date()

    downloadLink.href = url
    downloadLink.download = `export-${timestampExportFile}.csv`

    //add link to body and remove it after download
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const submitForm = e => {
    e.preventDefault()
    const form = e.currentTarget
    const formData = new FormData(form)

    fetchGetAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/crm/export`,
      method: 'GET',
      headers: {
        Authorization: userData.bearerToken,
      },
      formData: formData,
    }).then(r => {
      if (r.success) {
        downloadFile(r.content)
      }
      overlayToggleOpen(false)
    })
  }
  const getOrganisations = () => {
    //add default all
    const options = [
      {
        id: 'all',
        index: 'all',
        title: 'All',
      },
    ]

    Object.keys(organisations).map(key =>
      options.push({
        id: organisations[key],
        index: organisations[key],
        title: organisations[key].replace(/^./, str => str.toUpperCase()),
      }),
    )

    return options
  }

  const getType = () => {
    //add default all
    const options = [
      {
        id: 'all',
        index: 'all',
        title: 'All',
      },
    ]

    Object.keys(companyTypes).map(key =>
      options.push({
        id: companyTypes[key],
        index: companyTypes[key],
        title: companyTypes[key].replace(/^./, str => str.toUpperCase()),
      }),
    )

    return options
  }
  const getListTypes = () => {
    //add default all
    const options = [
      {
        id: 'companies',
        index: 'companies',
        title: 'Bedrijven',
      },
      {
        id: 'contacts',
        index: 'contacts',
        title: 'Contacten',
      },
    ]

    return options
  }

  return (
    <form className="exportForm" onSubmit={e => submitForm(e)}>
      <div className="formWrapper">
        <SelectMat
          id="list_type"
          name="list_type"
          label="Lijst type"
          items={getListTypes()}
          prefill={selectedListType}
          onChange={r => setSelectedListType(r)}
          hideFirstOptionEmpty
        />
        <SelectMat
          id="organisation"
          name="organisation"
          label="Organisatie"
          items={getOrganisations()}
          prefill={selectedOrganisation}
          onChange={r => setSelectedOrganisation(r)}
          hideFirstOptionEmpty
        />
        <SelectMat
          id="company_type"
          name="type"
          label="Type bedrijf"
          items={getType()}
          prefill={selectedType}
          onChange={r => setSelectedType(r)}
          hideFirstOptionEmpty
        />
      </div>
      <Button variant="contained" type="submit">
        export
      </Button>
    </form>
  )
}

export default ExportCRMForm
