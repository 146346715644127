export const stateName = 'TIMESHEET'
import { pending, success, error, reset } from '../../redux/configureStore'

const initState = {
  fetching: false,
  fetched: false,
  data: {
    /*headers: ["Status", "Dag", "Datum", "Uren", ""],
    break: 30,
    dayEntries: [
      {
        date: "2021-05-03",
        startTime: "09:00",
        endTime: "17:00",
        entries: [
          {
            start: "9:15",
            end: "10:15",
          },
          {
            start: "13:00",
            end: "20:00",
          },
        ],
      },
      {
        date: "2021-05-04",
        startTime: "09:00",
        endTime: "17:00",
        entries: [
          {
            start: "9:15",
            end: "10:15",
          },
          {
            start: "13:00",
            end: "20:00",
          },
        ],
      },
      {
        date: "2021-05-05",
        startTime: "09:00",
        endTime: "17:00",
        entries: [
          {
            start: "9:15",
            end: "10:15",
          },
        ],
      },
      {
        date: "2021-05-06",
        startTime: "09:00",
        endTime: "17:00",
        entries: [
          {
            start: "9:15",
            end: "10:15",
          },
          {
            start: "13:00",
            end: "20:00",
          },
        ],
      },
      {
        date: "2021-05-07",
        startTime: "09:00",
        endTime: "17:00",
        entries: [
          {
            start: "9:15",
            end: "10:15",
          },
        ],
      },
      {
        date: "2021-05-10",
        startTime: "09:00",
        endTime: "17:00",
        entries: [
          {
            start: "9:15",
            end: "10:15",
          },
          {
            start: "13:00",
            end: "20:05",
          },
        ],
      },
      {
        date: "2021-05-11",
        startTime: "09:00",
        endTime: "17:00",
        entries: [],
      },
      {
        date: "2021-05-12",
        startTime: "09:00",
        endTime: "17:00",
        entries: [],
      },
      {
        date: "2021-05-13",
        startTime: "09:00",
        endTime: "17:00",
        entries: [],
      },
    ],*/
  },
  error: {},

  /*date: "2021-04-27",
  startTime: "09:00",
  endTime: "17:00",
  break: 30,
  headers: ["Duur-tijd", "Project", "Taak", "Beschrijving"],
  entries: [
    {
      id: "1",
      start: "9:15",
      end: "10:15",
      fields: {
        time: "",
        project: {
          title: "Lorem",
          subtitle: "Ipsum",
        },
        task: "Lorem1",
        desc: "loremdsfgdgfdgfdgfdgfdgfd",
      },
    },
    {
      id: "3",
      start: "13:00",
      end: "20:00",
      fields: {
        time: "",
        project: {
          title: "Lorem",
          subtitle: "Ipsum",
        },
        task: "lorem",
        desc: "loremdsfgdgfdgfdgfdgfdgfd",
      },
    },
  ],*/
}

export default (state = initState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case `${stateName}${reset}`:
      return {
        fetching: false,
        fetched: false,
        data: {},
        error: {},
      }
    case `${stateName}_GET${pending}`:
      return {
        fetching: true,
        fetched: false,
        data: {},
        error: {},
      }

    case `${stateName}_GET${success}`:
      return {
        fetching: false,
        fetched: true,
        data: action.payload,
        error: {},
      }

    case `${stateName}_GET${error}`:
      return {
        fetching: false,
        fetched: true,
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}
