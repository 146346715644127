import React from 'react'
import TimesheetDay from '../timesheetDay/TimesheetDay'
import TimesheetWeek from '../timesheetWeek/TimesheetWeek'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSelector } from 'react-redux'
import { overlayToggleOpen } from '../overlay/api'
import { timesheetCheckForEntries, timesheetFetchNewData } from './api'
import { fetchPostJsonAPI } from '../../../general/BuddyFuncs'
import ProjectOverview from '../projectOverview/ProjectOverview'
import Button from '@mui/material/Button'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import ExportBtn from './components/ExportBtn'

const Timesheet = () => {
  const fromTo = useSelector(state => state.timesheetFilterReducer.fromTo)
  const content = useSelector(state => state.timesheetReducer.data)
  const token = useSelector(state => state.loginReducer.data.bearerToken)
  const selectedType = useSelector(state => state.typeSelectorReducer.selected)
  const userData = useSelector(state => state.loginReducer.data)

  const entryBtns = [
    {
      icon: <DeleteIcon fontSize="large" />,
      title: 'removeItem',
      onClick: (e, id) => {
        e.preventDefault()
        //delete the entry from the list
        fetchPostJsonAPI({
          url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/record`,
          headers: {
            Authorization: token,
          },
          json: {
            id: id,
          },
          method: 'DELETE',
        }).then(r => {
          timesheetFetchNewData()
        })
      },
    },
  ]

  timesheetCheckForEntries(fromTo)
  const handleAddTimesheetEntry = e => {
    overlayToggleOpen(
      true,
      'timesheet',
      'Werktijd ingeven',
      selectedType.index ? { userID: selectedType.index } : {},
    )
  }

  const createDayOrWeek = () => {
    if (fromTo.startDate === fromTo.endDate) {
      return <TimesheetDay entryBtns={entryBtns} content={content} />
    } else {
      return <TimesheetWeek content={content} fromTo={fromTo} />
    }
  }

  const projectOrUser = () => {
    if (selectedType.type === undefined || selectedType.type === 'user') {
      //is user
      return createDayOrWeek()
    } else {
      //is project
      return <ProjectOverview content={content} />
    }
  }

  const isActiveUserSelected = () => {
    if (userData.profile && selectedType.index) {
      if (
        parseInt(userData.profile.id) !== selectedType.index &&
        userData.profile.roles_id > 2
      ) {
        return false
      }
    }

    return true
  }

  return (
    <div className="timesheet">
      <div style={{ textAlign: 'right' }}>
        <ExportBtn />
      </div>
      {projectOrUser()}
      {isActiveUserSelected() ? (
        <Button
          variant="contained"
          className="addTimesheetEntry"
          onClick={handleAddTimesheetEntry}
        >
          <AccessTimeOutlinedIcon /> &nbsp;Werktijd ingeven
          {selectedType.index &&
          selectedType.type == 'user' &&
          selectedType.index != userData.profile.id
            ? ' (' + selectedType.title + ')'
            : ''}
        </Button>
      ) : null}
    </div>
  )
}

export default Timesheet
