import { store } from '../../redux/configureStore'
import { setNewPassword, resetPasswordReducer } from './createPaswordAction'

export const newPasswordRequest = passwordData => {
  store.dispatch(setNewPassword(passwordData))
}

export const passwordReducerReset = () => {
  store.dispatch(resetPasswordReducer())
}
