import React, { useState } from 'react'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

const DateSelect = props => {
  //"2014-08-18T21:11:00"
  const [date, setDate] = useState(null)

  const handleChange = date => {
    setDate(date)

    if (props.onChange) {
      props.onChange(date)
    }
  }

  const valDate = props.value === undefined ? date : props.value

  return (
    <div
      id={props.id}
      className="dateSelect input"
      style={
        props.style !== null && props.style !== undefined ? props.style : {}
      }
    >
      <DatePicker
        disabled={props.disabled !== null && props.disabled !== undefined}
        inputVariant="outlined"
        disableToolbar
        variant="inline"
        inputFormat="dd/MM/yyyy"
        name={props.name}
        label={props.label}
        value={
          valDate
            ? valDate
            : props.prefill
            ? new Date(props.prefill)
            : new Date()
        }
        onChange={handleChange}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
        required={props.required !== null && props.required !== undefined}
        error={props.error}
        helperText={!!props.helperText ? props.helperText : ''}
        renderInput={props => <TextField {...props} />}
      />
    </div>
  )
}

export default DateSelect
