import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Table,
  TableBody,
  TableRow,
  Fab,
  Stack,
  Dialog,
  DialogContentText,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import { TableCell } from './TableCell'

export function CompanyInfo({ company, onEditClick, onDelete }) {
  const { t } = useTranslation()
  const [showDelete, setShowDelete] = useState(false)

  const companyCountries = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.countries || {},
  )
  const canDelete = useSelector(
    state => parseInt(state.loginReducer.data.profile.roles_id) <= 3,
  )

  if (!company) return null

  return (
    <>
      <Stack spacing={2} width={1}>
        <span className="cardTitle">BEDRIJF</span>
        <h3 className="uppercase">{company.naambedrijf}</h3>
        <Stack
          direction={{ xs: 'column', md: 'column', lg: 'column', xl: 'row' }}
        >
          <Table size="small" sx={{ width: 'auto', flex: 1 }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '160px' }}>
                  <b>{t('crm.company.company-name')}:</b>
                </TableCell>
                <TableCell>{`${company.naambedrijf} ${company.rechtsvorm}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan="2">
                  <b>{t('crm.company.billing-info')}:</b>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <b>{t('crm.company.address')}:</b>
                </TableCell>
                <TableCell>
                  {company.bedrijf_straat}
                  <br />
                  {company.bedrijf_adres2 ? company.bedrijf_adres2 : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{`${company.bedrijf_postcode} ${company.bedrijf_gemeente}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{companyCountries[company.bedrijf_land]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>{t('crm.company.phone')}:</b>
                </TableCell>
                <TableCell>{company.telefoon}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>{t('crm.company.email')}:</b>
                </TableCell>
                <TableCell>{company.email}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Stack flex={1}>
            <Table size="small" sx={{ width: 'auto' }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '160px' }}>
                    <b>{t('crm.company.iban')}:</b>
                  </TableCell>
                  <TableCell>{company.ibannr}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('crm.company.vat')}:</b>
                  </TableCell>
                  <TableCell>{`${company.btwprefix}${company.btwnummer}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>{t('crm.company.fax')}:</b>
                  </TableCell>
                  <TableCell>{company.fax}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Fab
                onClick={onEditClick}
                size="small"
                color="primary"
                variant="circular"
              >
                <Edit />
              </Fab>

              {canDelete && (
                <>
                  <Fab
                    onClick={() => setShowDelete(true)}
                    size="small"
                    color="secondary"
                    variant="circular"
                  >
                    <Delete />
                  </Fab>

                  <Dialog open={showDelete}>
                    <DialogContent>
                      <DialogContentText>
                        {t('crm.company.delete-notice')}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onDelete}
                      >
                        {t('general.confirm')}
                      </Button>
                      <Button onClick={() => setShowDelete(false)}>
                        {t('general.cancel')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
