import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import InputMat from '../../__core/components/InputMat'
import { submitResetAccountPassword } from './api'
import { Typography, Button } from '@mui/material'

export const ForgotPassword = () => {
  const [accountField, setAccountField] = useState('')
  const [accountFieldError, setAccountFieldError] = useState(false)
  const [accountFieldErrorValue, setAccountFieldErrorValue] = useState('')
  const [accountPasswordResetSuccess, setAccountPasswordResetSuccess] =
    useState(false)

  const accountData = useSelector(state => state.forgotPasswordComp.data)
  const prefillData = useSelector(
    state => state.forgotPasswordComp.prefillLogin,
  )

  useEffect(() => {
    if (typeof prefillData === 'undefined' || prefillData === '') return
    setAccountField(prefillData)
  }, [prefillData])

  useEffect(() => {
    if (typeof accountData.invalid !== 'undefined') {
      setAccountFieldErrorValue('Geen acccount gevonden voor dit e-mailadres.')
      setAccountFieldError(true)
    }

    if (accountData.success) {
      setAccountPasswordResetSuccess(true)
    }
  }, [accountData])

  const trackAccountField = e => {
    setAccountFieldError(false)
    setAccountFieldErrorValue('')

    setAccountField(e)
  }

  const submitForm = e => {
    e.preventDefault()
    const form = e.currentTarget
    const formData = Object.fromEntries(new FormData(form))
    const formDataST = new FormData()
    formDataST.append('email', formData.login)

    submitResetAccountPassword(formDataST)
  }

  return (
    <div className="login">
      {accountPasswordResetSuccess ? (
        <Typography variant="subtitle1">
          Er is een email met een reset link verstuurd naar: {accountField}
        </Typography>
      ) : (
        <form onSubmit={e => submitForm(e)}>
          <InputMat
            id={'username'}
            name={'login'}
            label={'Email'}
            prefill={accountField}
            onChange={trackAccountField}
            error={accountFieldError}
            errorText={accountFieldErrorValue}
          />
          <Button type="submit" variant="contained">
            Verstuur
          </Button>
        </form>
      )}
    </div>
  )
}
