import { pending, success, error, reset } from '../../redux/configureStore'

export const stateName = 'LOGIN'

const initState = {
  fetching: false,
  fetched: false,
  tokenOke: false,
  tokenChecked: false,
  data: {},
  error: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case `${stateName}_GET_USER${reset}`:
      //reset session storage
      sessionStorage.removeItem('token')
      state = undefined

      return {
        ...state,
        fetching: false,
        fetched: false,
        tokenOke: false,
        tokenChecked: false,
        data: {},
        error: {},
      }
    case `${stateName}_SET_TOKEN_CHECKED`:
      return {
        ...state,
        tokenChecked: action.payload,
      }
    case `${stateName}_GET_USER${pending}`:
      return {
        ...state,
        fetching: true,
        fetched: false,
        data: {},
        error: {},
      }

    case `${stateName}_GET_USER${success}`:
      //store the users token in the local storage
      if (!action.payload.success) {
        sessionStorage.removeItem('token')
      }
      if (action.payload.bearerToken) {
        sessionStorage.setItem('token', action.payload.bearerToken)
      } else {
        action.payload.bearerToken = sessionStorage.getItem('token')
      }

      return {
        ...state,
        fetching: false,
        fetched: true,
        tokenOke: true,
        data: action.payload,
        error: {},
      }

    case `${stateName}_GET_USER${error}`:
      return {
        ...state,
        fetching: false,
        fetched: true,
        tokenOke: true,
        data: {},
        error: action.payload,
      }

    default:
      return state
  }
}
