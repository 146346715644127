import React, { useState } from 'react'
import InputMat from './InputMat'
import Fade from '@mui/material/Fade'
import { DateRangePicker } from 'materialui-daterange-picker'
import { addLeadingZeros } from '../../../general/BuddyFuncs'

const DateRangeSelect = props => {
  const [dateRange, setDateRange] = useState({})
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(false)

  const handleFocus = e => {
    setDateRange({})
    setOpen(true)

    if (props.onChange) {
      props.onChange({})
    }
  }

  const createDate = dateClass => {
    const date = dateClass ? dateClass : new Date()

    return `${addLeadingZeros(date.getDate())}/${addLeadingZeros(
      date.getMonth() + 1,
    )}/${addLeadingZeros(date.getFullYear())}`
  }

  //close if values are set
  if (dateRange.startDate && dateRange.endDate && open) {
    setOpen(false)
  }

  const prefillDate = () => {
    const prefill = props.prefill ? props.prefill : dateRange

    return `${createDate(prefill.startDate)} - ${createDate(prefill.endDate)}`
  }

  return (
    <div className="dateRangeSelect">
      <InputMat
        {...props.inputParams}
        onFocus={handleFocus}
        prefill={prefillDate()}
      />
      <div className="dateRangePickerHolder">
        <Fade in={open}>
          <div>
            <DateRangePicker
              open={open}
              toggle={toggle}
              prefill={props.prefill ? props.prefill : dateRange}
              onChange={range => {
                //update the redux

                //update localy
                setDateRange(range)

                if (props.onChange) {
                  props.onChange(range)
                }
              }}
            />
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default DateRangeSelect
