import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { Avatar } from '@mui/material'
import BasePage from '../../react/components/basePage/BasePage'
import { Crm } from '../components/crm/Crm'
import EmployeePage from '../components/employeePage/EmployeePage'
import EventNoteRoundedIcon from '@material-ui/icons/EventNoteRounded'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import GroupRoundedIcon from '@material-ui/icons/GroupRounded'
import Heartbeat from 'react-heartbeat'
import MainHeader from '../__core/mainHeader'
import { OnBoarding } from '../components/onboarding/Onboarding'
import PersonRoundedIcon from '@material-ui/icons/PersonRounded'
import { ProjectSettings } from '../components/projectSettings/ProjectSettings'
import React from 'react'
import Timesheet from '../components/timesheet/Timesheet'
import TimesheetFilter from '../components/timesheetFilter/TimesheetFilter'
import TuneIcon from '@material-ui/icons/Tune'
import UserProfilePage from '../components/userProfilePage/UserProfilePage'
import { checkValidityOfToken } from './api'
import { heartbeat } from './api'
import { useSelector } from 'react-redux'

export default function () {
  const { tokenOke, userData, user, tokenChecked } = useSelector(state => {
    return {
      tokenOke: state.loginReducer.tokenOke,
      userData: state.loginReducer.data,
      user: state.loginReducer,
      tokenChecked: state.loginReducer.tokenChecked,
    }
  })

  const rolesId = userData.profile ? userData.profile.roles_id : 4

  const routes = [
    {
      comp: (
        <BasePage
          pageTitle="CRM"
          pageClass="crm"
          headerComponents={[]}
          pageContent={<Crm />}
        />
      ),
      navItem: {
        title: 'CRM',
        icon: <FormatListBulletedIcon />,
        link: `/crm/companies`,
        isActive: false,
      },
      props: {
        path: `/crm`,
      },
    },
    {
      comp: (
        <BasePage
          pageTitle="Tijdregistratie"
          pageClass="timesheet"
          headerComponents={
            rolesId < 4 ? [<TimesheetFilter />] : [<TimesheetFilter />]
          }
          pageContent={<Timesheet />}
        />
      ),
      navItem: {
        title: 'Tijdregistratie',
        icon: <EventNoteRoundedIcon />,
        link: `/`,
        isActive: true,
      },
      props: {
        exact: true,
        path: `/`,
      },
    },
    {
      comp: (
        <BasePage
          pageTitle="Medewerkers"
          pageClass="employees"
          headerComponents={[]}
          pageContent={<EmployeePage />}
        />
      ),
      navItem: {
        adminLevel: 3,
        title: 'Medewerkers',
        icon: <GroupRoundedIcon />,
        link: `/employees`,
        isActive: false,
      },
      props: {
        path: `/employees`,
      },
    },
    {
      comp: (
        <BasePage
          pageTitle="Projecten overzicht"
          pageClass="projectOverview"
          headerComponents={[]}
          pageContent={<ProjectSettings />}
        />
      ),
      navItem: {
        adminLevel: 3,
        title: 'Projecten',
        icon: <TuneIcon />,
        link: `/projecten-overzicht`,
        isActive: false,
      },
      props: {
        path: `/projecten-overzicht`,
      },
    },
    {
      comp: (
        <BasePage
          pageTitle="Mijn profiel"
          pageClass="myProfile"
          headerComponents={[]}
          pageContent={<UserProfilePage />}
        />
      ),
      navItem: {
        title: 'Mijn profiel',
        icon:
          userData.avatar == '' ? (
            <PersonRoundedIcon />
          ) : (
            <Avatar src={userData.avatar} />
          ),
        link: `/profile`,
        isActive: false,
      },
      props: {
        path: `/profile`,
      },
    },
  ]

  const handleHeartbeat = e => {
    heartbeat()
  }

  if (!tokenOke && !tokenChecked && !user.fetching) {
    checkValidityOfToken()
  }

  return (
    <>
      <BrowserRouter basename={process.env.REACT_APP_ROUTE_URL}>
        <MainHeader menuItems={routes.map(route => route.navItem)} />
        {sessionStorage.getItem('token') && userData.success && tokenOke ? (
          <>
            <Heartbeat
              heartbeatFunction={e => handleHeartbeat(e)}
              heartbeatInterval={300000}
            />
            <main>
              <Switch>
                {routes.map((route, index) => {
                  return (
                    <Route key={`routekey${index}`} {...route.props}>
                      {route.comp}
                    </Route>
                  )
                })}
              </Switch>
            </main>
          </>
        ) : (
          <OnBoarding />
        )}
      </BrowserRouter>
    </>
  )
}
