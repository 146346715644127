import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { fetchPostJsonAPI } from '../../../general/BuddyFuncs'
import BaseInfo from './components/BaseInfo'
import ContactInfo from './components/ContactInfo'
import Organizations from './components/Organizations'
import Button from '@mui/material/Button'

const UserProfilePage = () => {
  const token = useSelector(state => state.loginReducer.data.bearerToken)

  const [disableSave, setDisableSave] = useState(true)

  const handleSaveUserData = e => {
    e.preventDefault()

    //convert form data to json
    const json = Object.fromEntries(new FormData(e.target))

    fetchPostJsonAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/update-profile`,
      headers: {
        Authorization: token,
      },
      json: json,
      method: 'PUT',
    }).then(r => {
      if (r.success) {
        setDisableSave(true)
      }
    })
  }

  return (
    <div className="userProfilePage">
      <div className="userProfileContainer">
        <form onSubmit={e => handleSaveUserData(e)}>
          <div className="columnHolder">
            <BaseInfo setDisableSave={setDisableSave} />
            <ContactInfo setDisableSave={setDisableSave} />
          </div>
          <Organizations />
          <Button type="submit" variant="contained" disabled={disableSave}>
            Opslaan
          </Button>
        </form>
      </div>
    </div>
  )
}

export default UserProfilePage
