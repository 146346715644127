import {
  Table,
  TableBody,
  TableRow,
  Fab,
  Stack,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { TableCell } from './TableCell'
import { useTranslation } from 'react-i18next'
import { Delete, Edit } from '@mui/icons-material'
import { useState } from 'react'

export function ContactInfo({ contact, company, onEditClick, onDelete }) {
  const { t } = useTranslation()
  const [showDelete, setShowDelete] = useState(false)

  const canDelete = useSelector(
    state => parseInt(state.loginReducer.data.profile.roles_id) <= 3,
  )
  const genders = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.gender || {},
  )
  const crmLanguages = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.languages || {},
  )
  const companyCountries = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.countries || {},
  )

  return (
    <>
      <Stack width={1} spacing={2}>
        <span className="cardTitle">CONTACT</span>
        <h3>{`${contact.voornaam} ${contact.naam}`}</h3>
        <Stack
          direction={{ xs: 'column', md: 'column', lg: 'column', xl: 'row' }}
        >
          <Table size="small" sx={{ width: 'auto', flex: 1 }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ width: '160px' }}>
                  <b>{t('crm.contact.name')}:</b>
                </TableCell>
                <TableCell>{`${contact.voornaam} ${contact.naam}`}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ verticalAlign: 'top' }}>
                  <b>{t('crm.contact.address')}:</b>
                </TableCell>
                <TableCell>
                  {contact.adres_copy ? (
                    <span style={{ color: 'lightgrey' }}>
                      {company.bedrijf_straat} {company.bedrijf_nr}
                      <br />
                      {company.bedrijf_adres2 ? company.bedrijf_adres2 : ''}
                    </span>
                  ) : (
                    <span>
                      {contact.straat}
                      <br />
                      {contact.adres2 ? contact.adres2 : ''}
                    </span>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  {contact.adres_copy ? (
                    <span style={{ color: 'lightgrey' }}>
                      {company.bedrijf_postcode} {company.bedrijf_gemeente}
                    </span>
                  ) : (
                    `${contact.postcode} ${contact.gemeente}`
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>
                  {contact.adres_copy ? (
                    <span style={{ color: 'lightgrey' }}>
                      {companyCountries[company.bedrijf_land] ?? ''}
                    </span>
                  ) : (
                    `${companyCountries[contact.land] ?? ''}`
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <b>{t('crm.contact.email')}:</b>
                </TableCell>
                <TableCell>{`${contact.email}`}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Stack flex={1}>
            <Table size="small" sx={{ width: 'auto' }}>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: '160px' }}>
                    <b>{t('crm.contact.gender')}:</b>
                  </TableCell>
                  <TableCell>{`${genders[contact.sex_id] ?? ''}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <b>{t('crm.contact.phone')}:</b>
                  </TableCell>
                  <TableCell>{`${contact.telefoon}`}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                    <b>{t('crm.contact.language')}:</b>
                  </TableCell>
                  <TableCell>{crmLanguages[contact.taal_id] ?? ''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>

            <Stack
              direction="row"
              spacing={2}
              sx={{
                flex: 1,
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
              }}
            >
              <Fab
                onClick={onEditClick}
                color="primary"
                size="small"
                variant="circular"
              >
                <Edit />
              </Fab>

              {canDelete && (
                <>
                  <Fab
                    onClick={() => setShowDelete(true)}
                    color="secondary"
                    size="small"
                    variant="circular"
                  >
                    <Delete />
                  </Fab>

                  <Dialog open={showDelete}>
                    <DialogContent>
                      <DialogContentText>
                        {t('crm.contact.delete-notice')}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={onDelete}
                      >
                        {t('general.confirm')}
                      </Button>
                      <Button onClick={() => setShowDelete(false)}>
                        {t('general.cancel')}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  )
}
