import { createTheme, ThemeProvider } from '@mui/material'
import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { store } from './redux/configureStore'
import Routes from './redux/Routes'
import './i18n'
import { useTranslation } from 'react-i18next'

const theme = createTheme({
  typography: {
    htmlFontSize: 10,
    fontFamily: 'Ubuntu',
  },
  palette: {
    common: {
      black: '#1b3330',
      white: '#fcffff',
    },
    primary: {
      main: '#245f95',
      contrastText: '#fcffff',
    },
    secondary: {
      main: '#d32f2f',
      contrastText: '#fff',
    },
    info: {
      light: '#245f95',
      main: '#245f95',
      dark: '#245f95',
      contrastText: '#fcffff',
    },
    grey: {
      50: '#ebf8fa',
      100: '#ebf8fa',
      200: '#ebf8fa',
      300: '#ebf8fa',
      400: '#ebf8fa',
      500: '#ebf8fa',
      600: '#ebf8fa',
      700: '#ebf8fa',
      800: '#ebf8fa',
      900: '#ebf8fa',
      A100: '#ebf8fa',
      A200: '#ebf8fa',
      A400: '#ebf8fa',
      A700: '#ebf8fa',
    },
    text: {
      primary: '#1b3330',
      secondary: '#1b3330',
      disabled: '#1b3330',
      hint: '#1b3330',
    },
  },
  MuiMenuItem: {
    button: {
      background: 'red',
    },
    root: {
      background: 'red',
    },
  },
})

const App = () => {
  const { t, i18n } = useTranslation()

  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </Suspense>
  )
}

export default App
