import createRootReducer from './rootReducer'
import promise from 'redux-promise-middleware'
import { configureStore } from '@reduxjs/toolkit'

export const pending = '_PENDING'
export const success = '_FULFILLED'
export const error = '_REJECTED'
export const reset = '_RESET'

export const store = configureStore({
  reducer: createRootReducer(),
  middleware: getDefaultMiddleware => [promise, ...getDefaultMiddleware()],
})
