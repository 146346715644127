import { store } from '../../redux/configureStore'
import {
  getUserAction,
  setUserDataAction,
  resetAction,
  setTokenCheckedAction,
} from './loginActions'

export const loginUser = loginData => {
  store.dispatch(getUserAction(loginData))
}

export const loginGetUserData = () => {
  store.dispatch(setUserDataAction())
}

export const loginReset = () => {
  store.dispatch(resetAction())
}

export const loginSetTokenChecked = checked => {
  store.dispatch(setTokenCheckedAction(checked))
}
