import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  TextField,
  CircularProgress,
  FormHelperText,
  Select,
  MenuItem,
  FormControl,
  Stack,
  InputLabel,
  Fab,
  IconButton,
  Button,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@mui/material'
import { Save, Close } from '@mui/icons-material'
import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import { validateEmail } from '../../../utils/validation'
import { useTranslation } from 'react-i18next'

const Form = styled('form')({
  width: '100%',
  alignItems: 'stretch',
})

export function ContactForm({ contact, onCancel, onSubmit }) {
  const { t } = useTranslation()
  const languages = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.languages || {},
  )
  const genders = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.gender || {},
  )

  const companyCountries = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.countries || {},
  )

  useEffect(() => {
    if (contact) {
      reset(contact)
    }
  }, [contact])

  const {
    register,
    watch,
    handleSubmit,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: contact,
    mode: 'onTouched',
  })

  const adres_copy = watch('adres_copy', true)

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <IconButton aria-label="delete" color="primary" onClick={onCancel}>
            <Close />
          </IconButton>
        </Stack>
        <Stack spacing={3} direction="row">
          <Stack spacing={1}>
            <TextField
              label={t('crm.contact.first-name')}
              variant="standard"
              type="text"
              size="small"
              style={{ flex: 1 }}
              {...register('voornaam', { required: true })}
            />
            {errors.voornaam && (
              <FormHelperText error>
                {t('crm.contact.errors.voornaam')}
              </FormHelperText>
            )}
          </Stack>

          <Stack spacing={1}>
            <TextField
              label={t('crm.contact.name')}
              variant="standard"
              type="text"
              size="small"
              style={{ flex: 1 }}
              {...register('naam', { required: true })}
            />
            {errors.naam && (
              <FormHelperText error>
                {t('crm.contact.errors.naam')}
              </FormHelperText>
            )}
          </Stack>
        </Stack>

        <Controller
          name="sex_id"
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel style={{ left: '-15px' }}>
                {t('crm.contact.gender')}
              </InputLabel>
              <Select defaultValue="" variant="standard" type="text" {...field}>
                {Object.keys(genders).map(key => (
                  <MenuItem key={key} value={key}>
                    {genders[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Controller
          name="taal_id"
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel style={{ left: '-15px' }}>
                {t('crm.contact.language')}
              </InputLabel>
              <Select defaultValue="" variant="standard" type="text" {...field}>
                {Object.keys(languages).map(key => (
                  <MenuItem key={key} value={key}>
                    {languages[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Stack spacing={1}>
          <TextField
            fullWidth
            label={t('crm.contact.email')}
            variant="standard"
            type="text"
            {...register('email', { validate: validateEmail })}
          />
          {errors.email && (
            <FormHelperText error>
              {t('crm.contact.errors.email')}
            </FormHelperText>
          )}
        </Stack>

        <TextField
          fullWidth
          label={t('crm.contact.phone')}
          variant="standard"
          type="text"
          {...register('telefoon')}
        />

        <TextField
          fullWidth
          label={t('crm.contact.fax')}
          variant="standard"
          type="text"
          {...register('fax')}
        />

        <Stack spacing={3} direction="row">
          &nbsp;
        </Stack>
        <FormGroup>
          <FormControlLabel
            control={<Switch {...register('adres_copy')} defaultChecked />}
            label={t('crm.contact.copy_address')}
          />
        </FormGroup>

        {!adres_copy ? (
          <>
            <TextField
              fullWidth
              label={t('crm.contact.street-details')}
              variant="standard"
              type="text"
              {...register('straat')}
            />

            <TextField
              fullWidth
              label={t('crm.company.address-line2')}
              variant="standard"
              type="text"
              {...register('adres2')}
            />

            <Stack spacing={3} direction="row">
              <TextField
                label={t('crm.contact.postal')}
                variant="standard"
                type="text"
                {...register('postcode')}
              />
              <TextField
                label={t('crm.contact.city')}
                variant="standard"
                type="text"
                {...register('gemeente')}
              />
            </Stack>

            <Controller
              name={'land'}
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel id="country-label" style={{ left: '-15px' }}>
                    {t('crm.contact.country')}
                  </InputLabel>
                  <Select
                    defaultValue=""
                    variant="standard"
                    type="text"
                    {...field}
                  >
                    {Object.keys(companyCountries).map(key => (
                      <MenuItem key={key} value={key}>
                        {companyCountries[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </>
        ) : null}

        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            {isSubmitting ? <CircularProgress /> : null} opslaan
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}
