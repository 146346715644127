import React from 'react'
import Overlay from '../overlay/Overlay'
import Timer from '../timer/Timer'
import TypeSelector from '../typeSelector/TypeSelector'

const BasePage = props => {
  return (
    <section
      className={`basePage` + (props.pageClass ? ' ' + props.pageClass : '')}
    >
      <header className="baseHeader">
        <h2>{props.pageTitle}</h2>
        <div className="headerCompHolder">
          {props.headerComponents
            ? props.headerComponents.map((comp, index) => {
                return (
                  <React.Fragment key={`headercomponent${index}`}>
                    {comp}
                  </React.Fragment>
                )
              })
            : null}
        </div>
        <Timer />
        <TypeSelector />
      </header>
      <div className="content">{props.pageContent}</div>
      <Overlay />
    </section>
  )
}

export default BasePage
