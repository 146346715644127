import { success, reset } from '../../redux/configureStore'

export const stateName = 'CREATE_PASSWORD'

const initState = {
  posted: false,
  tokenValid: false,
  data: {},
  error: {},
}

export default (state = initState, action) => {
  switch (action.type) {
    case `${stateName}_POST_PASSWORD${success}`:
      return {
        ...state,
        posted: true,
        tokenValid: false,
        data: action.payload,
        error: {},
      }
    case `${stateName}_CLEAR_PASSWORD_REDUCER${reset}`:
      return {
        ...state,
        posted: false,
        tokenValid: false,
        data: {},
        error: {},
      }
    default:
      return state
  }
}
