import React from 'react'
import SelectMat from '../../__core/components/SelectMat'
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded'
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded'
import { useSelector } from 'react-redux'
import { Avatar } from '@mui/material'
import { typeSelectorSetOptions, typeSelectorSetSelected } from './api'
import { timesheetFetchNewData } from '../timesheet/api'
import { overlayGetSelectOptions } from '../overlay/api'

const TypeSelector = () => {
  const selected = useSelector(state => state.typeSelectorReducer.selected)
  const options = useSelector(state => state.typeSelectorReducer.options)
  const optionsFromOverlay = useSelector(
    state => state.overlayReducer.selectOptions,
  )

  const createOptions = () => {
    const newOptions = []

    if (!optionsFromOverlay.fetched) {
      //get data
      overlayGetSelectOptions()
    } else if (optionsFromOverlay.data.users) {
      const users = optionsFromOverlay.data.users
      //add currentUser
      const activeUser = users ? users.filter(user => user.current === 1) : []

      if (activeUser.length === 0) return
      newOptions.push({
        type: 'user',
        id: `user${activeUser[0].id}`,
        index: `user${activeUser[0].id}`,
        title: activeUser[0].name,
        src: activeUser[0].avatar ? activeUser[0].avatar : '',
        icon: activeUser[0].avatar ? 'avatar' : '',
      })

      //add other users
      users.forEach(user => {
        if (user.current === 0) {
          newOptions.push({
            type: 'user',
            id: `user${user.id}`,
            index: `user${user.id}`,
            title: user.name,
            icon: '',
          })
        }
      })

      //add projects
      const projects = optionsFromOverlay.data.projects

      projects.forEach(project => {
        if (project.id > 0 && project.archived === 0) {
          newOptions.push({
            type: 'project',
            id: `project${project.id}`,
            index: `project${project.id}`,
            title: project.title + ' [' + project.subtitle + ']',
            icon: '',
          })
        }
      })
    }

    return newOptions
  }

  const addIconToOptions = () => {
    return options.map(option => {
      let newIcon = ''

      if (option.icon === '') {
        //no url so use default
        switch (option.type) {
          case 'user':
            newIcon = <AccountCircleRoundedIcon fontSize="inherit" />
            break
          case 'project':
            newIcon = <AssignmentRoundedIcon fontSize="inherit" />
            break
          default:
            newIcon = <AccountCircleRoundedIcon fontSize="inherit" />
            break
        }
      } else {
        //has url so use avatar
        newIcon = (
          <Avatar
            alt={option.title}
            src={option.src}
            style={{ width: '2.2rem', height: '2.2rem' }}
          />
        )
      }

      return { ...option, icon: newIcon }
    })
  }

  const handleChange = index => {
    options.forEach(option => {
      if (option.index === index) {
        //is selected option add data to redux

        typeSelectorSetSelected({
          ...option,
          index: parseInt(option.index.replace(/^\D+/g, '')),
        })

        //get new data for the time sheet
        timesheetFetchNewData()
      }
    })
  }

  //no options get them from overlay
  if (options && options.length === 0 && optionsFromOverlay.fetched) {
    typeSelectorSetOptions(createOptions())
  }

  const prefilltypeSelector = () => {
    if (selected.index && selected.type) {
      return `${selected.type}${selected.index}`
    }

    return null
  }

  return (
    <div className="typeSelector">
      {options && options.length > 0 ? (
        <SelectMat
          id={'typeSelector'}
          name="typeSelector"
          label={'Gebruiker / Project'}
          items={addIconToOptions()}
          size="small"
          prefill={prefilltypeSelector()}
          onChange={index => handleChange(index)}
          hideFirstOptionEmpty
        />
      ) : null}
    </div>
  )
}

export default TypeSelector
