import React, { useState } from 'react'
import {
  addLeadingZerosToTime,
  addMultipleTimeDiffs,
  addTimeDiff,
} from '../../../../general/BuddyFuncs'
import SublevelItem from './SublevelItem'

const SubLevel = props => {
  const [openHolder, setOpenHolder] = useState(false)

  const handleOpenHolder = e => {
    e.preventDefault()

    //toggle the holder
    setOpenHolder(!openHolder)
  }

  const calcTotalTime = () => {
    if (props.entries) {
      const time = addMultipleTimeDiffs(
        props.entries.map(entry => ({
          start: entry.start,
          end: entry.end,
        })),
      )

      return addLeadingZerosToTime(`${time.hours}:${time.minutes}`)
    }
  }

  return (
    <div className="subLevel card">
      <div className="subLevelHead">
        <button
          className={`squarebtn  ${openHolder ? 'btn' : 'ghostBtn'}`}
          onClick={e => handleOpenHolder(e)}
        >
          {props.entries.length}
        </button>
        <h3>{props.title}</h3>
        <p>{calcTotalTime()}</p>
      </div>
      <div className={`sublevelHolder ${openHolder ? 'open' : ''}`}>
        <table className="sublevelItems">
          <thead>
            <tr>
              <td className="fill">Beschrijving</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {props.entries.map(entry => {
              return <SublevelItem key={`subLevelItem${entry.id}`} {...entry} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SubLevel
