import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  selectCompany,
  updateContact,
  fetchCompanyById,
  deleteContactById,
} from '../crmSlice'
import { ContactForm } from '../forms/ContactForm'
import { ContactInfo } from './ContactInfo'
import { Item } from './Item'

export function ContactCard({ contact }) {
  const [edit, setEdit] = useState(false)
  const dispatch = useDispatch()
  const company = useSelector(selectCompany)

  const onDelete = async () => {
    await dispatch(deleteContactById(contact.id))
    await dispatch(fetchCompanyById(company.crm_bedrijf_id))
  }

  const onSubmit = async data => {
    await dispatch(updateContact(data))
    await dispatch(fetchCompanyById(company.crm_bedrijf_id))
    setEdit(false)
  }

  return (
    <Item elevation={3}>
      {!edit ? (
        <ContactInfo
          key={'info-' + contact}
          contact={contact}
          company={company}
          onDelete={onDelete}
          onEditClick={() => setEdit(true)}
        />
      ) : (
        <ContactForm
          key={'form-' + contact}
          contact={contact}
          onSubmit={onSubmit}
          onCancel={() => setEdit(false)}
        />
      )}
    </Item>
  )
}
