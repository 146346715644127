import React, { useState } from 'react'
import { addLeadingZeros, fetchGetAPI } from '../../../../general/BuddyFuncs'
import { useSelector } from 'react-redux'
import { overlayToggleOpen } from '../api'
import SelectMat from '../../../__core/components/SelectMat'
import DateRangeSelect from '../../../__core/components/DateRangeSelect'
import { employeeCheckIfFetched } from '../../employeePage/api'
import Button from '@mui/material/Button'

const ExportForm = () => {
  const userData = useSelector(state => state.loginReducer.data)
  const selectOptionsData = useSelector(
    state => state.overlayReducer.selectOptions.data,
  )
  const employeesData = useSelector(state => state.employeePageReducer.data)

  employeeCheckIfFetched()

  const [dateRange, setDateRange] = useState({})
  const [selectedOrganisation, setSelectedOrganisation] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedUser, setSelectedUser] = useState(null)

  const createSendDate = dateClass => {
    const date = dateClass ? dateClass : new Date()

    return `${addLeadingZeros(date.getFullYear())}-${addLeadingZeros(
      date.getMonth() + 1,
    )}-${addLeadingZeros(date.getDate())}`
  }

  const downloadFile = (content, startDate, endDate) => {
    const downloadLink = document.createElement('a')
    const blob = new Blob(['\ufeff', content])
    const url = URL.createObjectURL(blob)

    //add hide class
    downloadLink.classList.add('hide')

    //add attributes to link
    downloadLink.href = url
    downloadLink.download = `export-${startDate}-${endDate}.csv`

    //add link to body and remove it after download
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const submitForm = e => {
    e.preventDefault()
    const form = e.currentTarget
    const formData = new FormData(form)

    //add items
    formData.append('date_start', createSendDate(dateRange.startDate))
    formData.append('date_end', createSendDate(dateRange.endDate))

    //remove items that are emtpy
    for (const entry of formData.entries()) {
      if (parseInt(entry[1]) === 0) {
        //remove from the list
        formData.delete(entry[0])
      }
    }

    fetchGetAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/export`,
      method: 'GET',
      headers: {
        Authorization: userData.bearerToken,
      },
      formData: formData,
    }).then(r => {
      if (r.success) {
        downloadFile(
          r.content,
          createSendDate(dateRange.startDate),
          createSendDate(dateRange.endDate),
        )
      }
      overlayToggleOpen(false)
    })
  }

  const getOrganizations = () => {
    if (userData.profile) {
      const allOrgOptions = userData.profile.divisions.map((divi, index) => {
        const newDivi = {
          id: divi.divisions_id,
          index: divi.divisions_id,
          title: divi.divisions_name,
        }

        return newDivi
      })

      //if selected is null add init value
      if (selectedOrganisation === null) {
        setSelectedOrganisation('0')
        setSelectedProject('0')
        setSelectedUser('0')
      }

      return [
        {
          id: '0',
          index: '0',
          title: 'All',
        },
        ...allOrgOptions,
      ]
    }

    return []
  }

  const getProjects = () => {
    //add default all
    const options = [
      {
        id: '0',
        index: '0',
        title: 'All',
      },
    ]

    //use the selected orginization to make a project list
    if (selectedOrganisation !== null) {
      userData.profile.projects.forEach(project => {
        if (project.divisions_id === parseInt(selectedOrganisation)) {
          //is part of the orginization so export
          options.push({
            id: project.id,
            index: project.id,
            title: project.title,
          })
        }
      })
    }

    return options
  }

  const checkIfUserIsInDivision = (diviId, employee) => {
    return (
      employee.divisions.filter(divi => parseInt(divi.divisions_id) === diviId)
        .length > 0
    )
  }

  const getUsers = () => {
    //add default all
    const options = [
      {
        id: '0',
        index: '0',
        title: 'All',
      },
    ]

    //use the selected projects to make a user list
    if (selectedOrganisation !== null && employeesData.employees) {
      employeesData.employees.forEach(employee => {
        if (
          selectedOrganisation == 0 ||
          checkIfUserIsInDivision(parseInt(selectedOrganisation), employee)
        ) {
          //if general or linked to the orginization
          options.push({
            id: employee.users_id,
            index: employee.users_id,
            title: `${employee.first_name} ${employee.last_name}`,
          })
        }
      })
    }

    return options
  }

  return (
    <form className="exportForm" onSubmit={e => submitForm(e)}>
      <div className="formWrapper">
        <DateRangeSelect
          inputParams={{ label: 'Datum range', id: 'dateRange' }}
          onChange={r => setDateRange(r)}
        />
        <SelectMat
          id="organisation"
          name="division_id"
          label="Organisatie"
          items={getOrganizations()}
          prefill={selectedOrganisation}
          onChange={r => setSelectedOrganisation(r)}
          hideFirstOptionEmpty
        />
        <SelectMat
          id="project"
          name="project_id"
          label="Project"
          items={getProjects()}
          prefill={selectedProject}
          onChange={r => setSelectedProject(r)}
          hideFirstOptionEmpty
        />
        <SelectMat
          id="users"
          name="users_id"
          label="Gebruikers"
          items={getUsers()}
          prefill={selectedUser}
          onChange={r => setSelectedUser(r)}
          hideFirstOptionEmpty
        />
      </div>
      <Button variant="contained" type="submit">
        export
      </Button>
    </form>
  )
}

export default ExportForm
