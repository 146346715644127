import React from 'react'
import {
  addLeadingZerosToTime,
  addTimeDiff,
} from '../../../../general/BuddyFuncs'

const SublevelItem = props => {
  const calcTime = () => {
    const time = addTimeDiff(props.start, props.end)
    return addLeadingZerosToTime(`${time.hours}:${time.minutes}`)
  }

  return (
    <tr className="sublevelItem">
      <td>
        {props.fields.desc}
        <br />
        <span className="small">
          <strong>{props.fields.date} </strong> &mdash; {props.fields.time_from}{' '}
          tot {props.fields.time_till} [{props.fields.name}]
        </span>
      </td>
      <td>{calcTime()}</td>
    </tr>
  )
}

export default SublevelItem
