import axios from 'axios'
import * as ibantools from 'ibantools'

export const validateVatNumber = async (value, token) => {
  if (!value) return true

  const {
    data: { valid },
  } = await axios({
    url: `api/crm/vat-validation?vat=${value}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return valid
}

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const validateEmail = value => {
  if (!value) return true
  return emailRegex.test(value)
}

export const validateIban = value => {
  if (!value) return true
  return ibantools.isValidIBAN(value.replace(/ /g, ''))
}
