import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import Login from '../login/Login'
import { CreatePassword } from '../createPassword/CreatePassword'
import { ForgotPassword } from '../forgotPassword/ForgotPassword'

export const OnBoarding = () => {
  return (
    <BrowserRouter basename={process.env.REACT_APP_ROUTE_URL}>
      <Switch>
        <Route path="/access" component={CreatePassword} />
        <Route path="/reset-password" component={ForgotPassword} />
        <Route path="/" component={Login} />
      </Switch>
    </BrowserRouter>
  )
}
