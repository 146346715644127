import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

export default class ReactInitClass {
  constructor(
    params = {
      reactRoot: '#root',
    },
  ) {
    this.reactRootSelector = params.reactRoot
    this.reactRoot = null
  }

  init() {
    this.reactRoot = document.querySelector(this.reactRootSelector)

    if (this.reactRoot) {
      ReactDOM.render(<App />, this.reactRoot)
    }
  }
}
