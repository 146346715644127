import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  TextField,
  CircularProgress,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Fab,
  IconButton,
  Button,
} from '@mui/material'
import CloseIcon from '@material-ui/icons/Close'
import { styled } from '@mui/styles'
import { useSelector } from 'react-redux'
import {
  validateEmail,
  validateIban,
  validateVatNumber,
} from '../../../utils/validation'
import { useTranslation } from 'react-i18next'
import { Close, Save } from '@mui/icons-material'

const Form = styled('form')({
  width: '100%',
  alignItems: 'stretch',
})

export function CompanyForm({ company, onCancel, onSubmit }) {
  const token = useSelector(state => state.loginReducer.data.bearerToken)
  const { t } = useTranslation()
  const companyLegalForm = useSelector(
    state =>
      state.overlayReducer.selectOptions.data.crm?.companyLegalForm || {},
  )
  const companyCountries = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.countries || {},
  )
  const btwPrefixes = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.btwPrefix || {},
  )

  useEffect(() => {
    if (company) {
      reset(company)
    }
  }, [company])

  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: company,
    mode: 'onTouched',
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2}>
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <IconButton aria-label="delete" color="primary" onClick={onCancel}>
            <Close />
          </IconButton>
        </Stack>
        <Stack spacing={3} direction="row">
          <TextField
            label={t('crm.company.name')}
            variant="standard"
            type="text"
            size="small"
            style={{ flex: 1 }}
            {...register('naambedrijf', { required: true })}
          />
          {errors.naambedrijf && (
            <FormHelperText error>
              {t('crm.company.errors.naambedrijf')}
            </FormHelperText>
          )}
          <Controller
            name={'rechtsvorm'}
            control={control}
            render={({ field }) => (
              <FormControl style={{ width: '160px' }}>
                <InputLabel id="legalform-label" style={{ left: '-15px' }}>
                  {t('crm.company.legal-form')}
                </InputLabel>
                <Select
                  defaultValue=""
                  variant="standard"
                  type="text"
                  {...field}
                >
                  {Object.keys(companyLegalForm).map(key => (
                    <MenuItem key={key} value={key}>
                      {companyLegalForm[key]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Stack>

        <Stack spacing={1}>
          <Stack spacing={3} direction="row">
            <Controller
              name="btwprefix"
              control={control}
              render={({ field }) => (
                <FormControl style={{ width: '160px' }}>
                  <InputLabel id="vatprefix-label" style={{ left: '-15px' }}>
                    {t('crm.company.vat-prefix')}
                  </InputLabel>
                  <Select
                    defaultValue=""
                    variant="standard"
                    type="text"
                    {...field}
                  >
                    {Object.keys(btwPrefixes).map(key => (
                      <MenuItem key={key} value={key}>
                        {btwPrefixes[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            <TextField
              fullWidth
              label={t('crm.company.vat-number')}
              variant="standard"
              type="text"
              {...register('btwnummer', {
                validate: v =>
                  validateVatNumber(
                    `${getValues('btwprefix') || ''}${v}`,
                    token,
                  ),
              })}
            />
          </Stack>
          {errors.btwnummer && (
            <FormHelperText error>
              {t('crm.company.errors.btwnummer')}
            </FormHelperText>
          )}
        </Stack>

        <Stack spacing={1}>
          <TextField
            fullWidth
            label={t('crm.company.iban')}
            variant="standard"
            type="text"
            {...register('ibannr', { validate: validateIban })}
          />
          {errors.ibannr && (
            <FormHelperText error>
              {t('crm.company.errors.iban')}
            </FormHelperText>
          )}
        </Stack>

        <TextField
          fullWidth
          label={t('crm.company.phone')}
          variant="standard"
          type="text"
          {...register('telefoon')}
        />

        <TextField
          fullWidth
          label={t('crm.company.fax')}
          variant="standard"
          type="text"
          {...register('fax')}
        />

        <Stack spacing={1}>
          <TextField
            fullWidth
            label={t('crm.company.email')}
            variant="standard"
            type="text"
            {...register('email', { validate: validateEmail })}
          />
          {errors.email && (
            <FormHelperText error>
              {t('crm.company.errors.email')}
            </FormHelperText>
          )}
        </Stack>

        <Stack spacing={3} direction="row">
          &nbsp;
        </Stack>
        <TextField
          fullWidth
          label={t('crm.company.street-details')}
          variant="standard"
          type="text"
          {...register('bedrijf_straat')}
        />

        <TextField
          fullWidth
          label={t('crm.company.address-line2')}
          variant="standard"
          type="text"
          {...register('bedrijf_adres2')}
        />

        <Stack spacing={3} direction="row">
          <TextField
            label={t('crm.company.postal')}
            variant="standard"
            type="text"
            {...register('bedrijf_postcode')}
          />
          <TextField
            label={t('crm.company.city')}
            variant="standard"
            type="text"
            {...register('bedrijf_gemeente')}
          />
        </Stack>

        <Controller
          name={'bedrijf_land'}
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel id="country-label" style={{ left: '-15px' }}>
                {t('crm.company.country')}
              </InputLabel>
              <Select defaultValue="" variant="standard" type="text" {...field}>
                {Object.keys(companyCountries).map(key => (
                  <MenuItem key={key} value={key}>
                    {companyCountries[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
          >
            opslaan
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}
