export const stateName = 'OVERLAY'
import { pending, success, error, reset } from '../../redux/configureStore'

const initState = {
  open: false,
  title: 'Tester',
  formType: '',
  prefill: {},
  selectOptions: {
    fetching: false,
    fetched: false,
    data: {},
  },
}

export default (state = initState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case `${stateName}_TOGGLE_OPEN`:
      return {
        ...state,
        open: action.payload,
      }
    case `${stateName}_SET_TITLE`:
      return {
        ...state,
        title: action.payload,
      }
    case `${stateName}_SET_FORMTYPE`:
      return {
        ...state,
        formType: action.payload,
      }
    case `${stateName}_SET_PREFILL`:
      return {
        ...state,
        prefill: action.payload,
      }
    case `${stateName}_SET_SELECT_OPTIONS${pending}`:
      return {
        ...state,
        selectOptions: {
          fetching: true,
          fetched: false,
          data: {},
        },
      }
    case `${stateName}_SET_SELECT_OPTIONS${success}`:
      return {
        ...state,
        selectOptions: {
          fetching: false,
          fetched: true,
          data: action.payload,
        },
      }
    default:
      return state
  }
}
