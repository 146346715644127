import React, { useEffect } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@mui/material'
import TimesheetEntryForm from './forms/TimesheetEntryForm'
import { useSelector } from 'react-redux'
import { overlayToggleOpen, overlayGetSelectOptions } from './api'
import ExportForm from './forms/ExportForm'
import ExportCRMForm from './forms/ExportCRMForm'
import EmployeeForm from './forms/EmployeeForm'
import OrganizationForm from './forms/OrganizationForm'

const Overlay = () => {
  const open = useSelector(state => state.overlayReducer.open)
  const title = useSelector(state => state.overlayReducer.title)
  const formType = useSelector(state => state.overlayReducer.formType)

  const handleCloseOverlay = e => {
    overlayToggleOpen(false)
  }

  useEffect(() => {
    overlayGetSelectOptions()
  }, [])

  const getForm = () => {
    switch (formType) {
      case 'timesheet':
        return <TimesheetEntryForm />

      case 'export':
        return <ExportForm />

      case 'export-crm':
        return <ExportCRMForm />

      case 'employee':
        return <EmployeeForm />

      case 'organization':
        return <OrganizationForm />

      default:
        return null
    }
  }

  return (
    <div className={`overlay ${open ? 'open' : ''}`}>
      <div className="panel card">
        <header className="overlayPanelHeader">
          <h3>{title}</h3>
          <button
            className="btn btnSquare roundBtn iconBtn iconClose"
            onClick={e => handleCloseOverlay(e)}
          >
            <CloseIcon />
            <span className="hide"></span>
          </button>
        </header>
        <div className="form">{getForm()}</div>
      </div>
    </div>
  )
}

export default Overlay
