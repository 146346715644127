import React from 'react'
import SubLevel from './components/SubLevel'
import Totals from './components/Totals'

const ProjectOverview = props => {
  const entriesToTasks = () => {
    if (props.content.entries) {
      const tasks = []

      props.content.entries.forEach(entry => {
        const task = entry.fields.task
        const taskIndex = taskExists(tasks, task)

        if (taskIndex > -1) {
          //the task already exists so add this entry in there
          tasks[taskIndex].entries.push(entry)
        } else {
          //no task so create new one
          tasks.push({
            title: task,
            entries: [entry],
          })
        }
      })

      return tasks
    }

    return []
  }

  const taskExists = (tasks, currentTask) => {
    let exists = -1

    //loop tasks
    tasks.forEach((task, index) => {
      if (task.title === currentTask) {
        exists = index
      }
    })

    return exists
  }

  const contentItems = entriesToTasks()

  return contentItems && contentItems.length > 0 ? (
    <div className="projectOverview">
      <div className="head">
        <p className="small">Taak</p>
        <p className="small">Duur</p>
      </div>
      <div className="subLevelHolder">
        {contentItems.map((task, index) => (
          <SubLevel key={`projectoverview${task.title}${index}`} {...task} />
        ))}
      </div>
      <Totals entries={props.content.entries} />
    </div>
  ) : (
    <div className="noEntries">
      Er is nog tijd geregistreerd voor dit project.
    </div>
  )
}

export default ProjectOverview
