import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import 'babel-polyfill'

export const fetchCompanies = createAsyncThunk(
  'crm/fetchCompanies',
  async (_, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    const response = await axios({
      method: 'get',
      url: `api/crm/companies`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.results
  },
)

export const fetchCompanyById = createAsyncThunk(
  'crm/fetchCompany',
  async (id, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    const response = await axios({
      method: 'get',
      url: `api/crm/company?id=${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return response.data.result
  },
)

export const deleteCompanyById = createAsyncThunk(
  'crm/deleteCompany',
  async (id, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      method: 'delete',
      url: `api/crm/company`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { id },
    })
  },
)

export const updateCompany = createAsyncThunk(
  'crm/updateCompany',
  async (company, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      url: `api/crm/company`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: company,
    })
  },
)

export const deleteCompanyLink = createAsyncThunk(
  'crm/deleteCompanyLink',
  async (id, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      url: `api/crm/company-lnk-org`,
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { id },
    })
  },
)

export const createCompanyLink = createAsyncThunk(
  'crm/createCompanyLink',
  async (link, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      url: `api/crm/company-lnk-org`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: link,
    })
  },
)

export const updateContact = createAsyncThunk(
  'crm/updateCompany',
  async (contact, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      url: `api/crm/contact`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: contact,
    })
  },
)

export const deleteContactById = createAsyncThunk(
  'crm/deleteCompany',
  async (id, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      method: 'delete',
      url: `api/crm/contact`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { id },
    })
  },
)

export const deleteContactLink = createAsyncThunk(
  'crm/deleteContactLink',
  async (id, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      url: `api/crm/contact-lnk-org`,
      method: 'delete',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { id },
    })
  },
)

export const createContactLink = createAsyncThunk(
  'crm/createContactLink',
  async (link, thunkAPI) => {
    const token = thunkAPI.getState().loginReducer.data.bearerToken
    await axios({
      url: `api/crm/contact-lnk-org`,
      method: 'put',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: link,
    })
  },
)

const initialState = {
  company: null,
  companies: [],
  contacts: [],
  filterdCompanies: [],
  filterdContacts: [],
  status: 'idle',
  crmStatus: 'idle',
  error: null,
}

export const crmSlice = createSlice({
  name: 'crm',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getAllCompaniesSucces: (state, action) => {
      state.companies = action.payload.companies
    },
    searchCompanies: (state, value) => {
      state.filterdCompanies = state.companies.filter(x =>
        x.naambedrijf.toUpperCase().match(value.payload.toUpperCase()),
      )
    },
    searchContacts: (state, value) => {
      state.filterdContacts = state.contacts.filter(x => {
        let fullName = `${x?.voornaam.toUpperCase()} ${x?.naam.toUpperCase()}`
        return fullName.match(value.payload.toUpperCase())
      })
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(fetchCompanies.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.companies = action.payload.sort(function (a, b) {
          a = a.naambedrijf.toLowerCase()
          b = b.naambedrijf.toLowerCase()

          return a < b ? -1 : a > b ? 1 : 0
        })
        state.contacts = action.payload.flatMap(x => x.contacts)
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
      .addCase(fetchCompanyById.pending, state => {
        state.crmStatus = 'loading'
      })
      .addCase(fetchCompanyById.fulfilled, (state, action) => {
        state.crmStatus = 'succeeded'
        state.company = action.payload
      })
      .addCase(fetchCompanyById.rejected, (state, action) => {
        state.crmStatus = 'failed'
        state.error = action.error.message
      })
  },
})

export const { searchCompanies, searchContacts, getAllCompaniesSucces } =
  crmSlice.actions

// export const selectCompanies = (state) => state.crm.companies;
// export const selectFilterdCompanies = (state) => state.crm.filterdCompanies;
export const selectContacts = state => state.crm.contacts
export const selectFilterdContacts = state => state.crm.filterdContacts
export const selectCmsStatus = state => state.crm.status
export const selectCrmStatus = state => state.crm.crmStatus
export const selectCompany = state => state.crm.company

export default crmSlice.reducer
