import { fetchAPI, fetchPostAPI } from '../../../general/BuddyFuncs'
import { reset } from '../../redux/configureStore'
import { stateName } from './loginReducer'

export const getUserAction = loginData => {
  return {
    type: `${stateName}_GET_USER`,
    payload: fetchPostAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/login`,
      method: 'POST',
      formData: loginData,
    }),
  }
}

export const setUserDataAction = () => {
  const token = sessionStorage.getItem('token')

  return {
    type: `${stateName}_GET_USER`,
    payload: fetchAPI(`${process.env.REACT_APP_PUBLIC_URL}api/user/profile`, {
      Authorization: token,
    }),
  }
}

export const resetAction = () => {
  return {
    type: `${stateName}_GET_USER${reset}`,
    payload: false,
  }
}

export const setTokenCheckedAction = checked => {
  return {
    type: `${stateName}_SET_TOKEN_CHECKED`,
    payload: checked,
  }
}
