import { store } from '../../redux/configureStore'
import {
  resetAccountPassword,
  resetAccountPrefill,
} from './forgotPasswordActions'

export const submitResetAccountPassword = accountData => {
  store.dispatch(resetAccountPassword(accountData))
}

export const submitAccountPrefill = emailField => {
  store.dispatch(resetAccountPrefill(emailField))
}
