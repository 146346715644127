import React from 'react'

const TotalPercentage = props => {
  return (
    <div className="totalPercentage">
      <p className="percent">{props.percent}%</p>
      <svg>
        <defs>
          <linearGradient id="defaultGradient">
            <stop offset="0%" stopColor="#245f95" />
            <stop offset="100%" stopColor="#245f95" />
          </linearGradient>
          <linearGradient id="errorGradient">
            <stop offset="0%" stopColor="#f0b6b6" />
            <stop offset="100%" stopColor="#d67171" />
          </linearGradient>
        </defs>
        <circle cx="27" cy="27" r="22.5"></circle>
        <circle
          cx="27"
          cy="27"
          r="22.5"
          style={{ strokeDashoffset: 150 - (150 * props.percent) / 100 }}
        ></circle>
      </svg>
    </div>
  )
}

export default TotalPercentage
