import React from 'react'
import { useSelector } from 'react-redux'
import WorkRoundedIcon from '@material-ui/icons/WorkRounded'

const Organizations = () => {
  const profileInfo = useSelector(state => state.loginReducer.data)

  return (
    <div className="organizations">
      <legend>Organisaties</legend>
      <div className="organizationsHolder">
        {profileInfo.profile
          ? profileInfo.profile.divisions.map(divi => {
              return (
                <div
                  className="card organisationCard"
                  key={`userpofile${divi.divisions_name}${divi.divisions_id}`}
                >
                  <div className="iconHolder">
                    <WorkRoundedIcon fontSize="large" />
                  </div>
                  <h4>{divi.divisions_name}</h4>
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}

export default Organizations
