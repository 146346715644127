import React, { useState } from 'react'
import { DateRangePicker } from 'materialui-daterange-picker'
import InputMat from '../../__core/components/InputMat'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import { addLeadingZeros } from '../../../general/BuddyFuncs'
import { timesheetFilterSetFomTo } from './api'

const TimesheetFilter = props => {
  const [dateRange, setDateRange] = useState({})
  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(false)

  const handleFocus = e => {
    setAnchorEl(e.currentTarget)
    setDateRange({})
    setOpen(true)
  }

  const createDate = dateClass => {
    const date = dateClass ? dateClass : new Date()

    return `${addLeadingZeros(date.getDate())}/${addLeadingZeros(
      date.getMonth() + 1,
    )}/${addLeadingZeros(date.getFullYear())}`
  }

  const createSendDate = dateClass => {
    const date = dateClass ? dateClass : new Date()

    return `${addLeadingZeros(date.getFullYear())}-${addLeadingZeros(
      date.getMonth() + 1,
    )}-${addLeadingZeros(date.getDate())}`
  }

  //close if values are set
  if (dateRange.startDate && dateRange.endDate && open) {
    setOpen(false)
  }

  return (
    <div className="timesheetFilter">
      <InputMat
        label="Datum range"
        id="dateRange"
        size="small"
        onFocus={handleFocus}
        prefill={`${createDate(dateRange.startDate)} - ${createDate(
          dateRange.endDate,
        )}`}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div>
              <DateRangePicker
                open={open}
                toggle={toggle}
                onChange={range => {
                  //update the redux
                  timesheetFilterSetFomTo({
                    startDate: createSendDate(new Date(range.startDate)),
                    endDate: createSendDate(new Date(range.endDate)),
                  })
                  //update localy
                  setDateRange(range)
                }}
              />
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  )
}

export default TimesheetFilter
