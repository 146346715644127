export const stateName = 'EMPLOYEES'
import { pending, success, error, reset } from '../../redux/configureStore'

const initState = {
  fetching: false,
  fetched: false,
  data: {},
  error: {},
}

export default (state = initState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case `${stateName}_GET${reset}`:
      return {
        ...initState,
        fetching: false,
        fetched: false,
        data: {},
        error: {},
      }

    case `${stateName}_GET${pending}`:
      return {
        ...initState,
        fetching: true,
        fetched: false,
        data: {},
        error: {},
      }
    case `${stateName}_GET${success}`:
      return {
        ...initState,
        fetching: false,
        fetched: true,
        data: action.payload,
        error: {},
      }

    case `${stateName}_GET${error}`:
      return {
        ...initState,
        fetching: false,
        fetched: true,
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}
