import React, { useState, useEffect } from 'react'
import TimerRoundedIcon from '@material-ui/icons/TimerRounded'
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline'
import useInterval from '@use-it/interval'
import { format, intervalToDuration } from 'date-fns'
import { overlayToggleOpen } from '../overlay/api'

const LOCALSTORAGE_KEY = 'timer_'
const useTimer = onFinish => {
  const localStorageStart = localStorage.getItem(LOCALSTORAGE_KEY + 'startDate')

  const [start, setStart] = useState(
    localStorageStart ? new Date(parseInt(localStorageStart)) : null,
  )
  const isRunning = !!start

  const [duration, setDuration] = useState({})

  useInterval(
    () => {
      setDuration(intervalToDuration({ start, end: new Date() }))
    },
    isRunning ? 1000 : null,
  )

  const startTimer = () => {
    if (isRunning) return null
    const now = new Date()
    localStorage.setItem(LOCALSTORAGE_KEY + 'startDate', now.valueOf())
    setStart(now)
  }

  const stopTimer = () => {
    if (!isRunning) return null
    const now = new Date()
    onFinish(start, now)
    setStart(null)
    setDuration({})
    localStorage.setItem(LOCALSTORAGE_KEY + 'startDate', '')
  }

  return {
    start: startTimer,
    stop: stopTimer,
    isRunning,
    seconds: duration.seconds || 0,
    minutes: duration.minutes || 0,
    hours: duration.hours || 0,
  }
}

const Timer = () => {
  const onFinish = (start, end) => {
    overlayToggleOpen(true, 'timesheet', 'Entry aanmaken', {
      time: {
        start: format(start, 'HH:mm'),
        end: format(end, 'HH:mm'),
      },
    })
  }

  const { isRunning, start, stop, hours, minutes, seconds } = useTimer(onFinish)

  const onClick = () => {
    if (!isRunning) {
      localStorage.setItem('timer_startDate', Date.now())

      start()
    }
    if (isRunning) {
      stop()
    }
  }

  const durationString = [
    hours ? `${hours}`.padStart(2, '0') : null,
    `${minutes}`.padStart(2, '0'),
    `${seconds}`.padStart(2, '0'),
  ]
    .filter(Boolean)
    .join(':')

  return (
    <div className="timer">
      <div className="timerBtn" onClick={onClick}>
        <div className="icon">
          {!isRunning ? (
            <TimerRoundedIcon fontSize="inherit" />
          ) : (
            <PauseCircleOutlineIcon fontSize="inherit" />
          )}
        </div>
        <div className="time">{durationString}</div>
      </div>
    </div>
  )
}

export default Timer
