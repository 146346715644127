const initState = {
  fromTo: {
    startDate: '',
    endDate: '',
  },
}

export default (state = initState, action) => {
  const newState = { ...state }
  switch (action.type) {
    case 'TIMESHEET_FILTER_SET_FROMTO':
      return {
        ...state,
        fromTo: action.payload,
      }
    default:
      return state
  }
}
