import React from 'react'

import { fetchCompanies, searchCompanies } from '../crmSlice'
import { Link } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { TextField, Button } from '@mui/material'
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export function CompaniesTable() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const companies = useSelector(state => state.crm.companies)
  const filterdCompanies = useSelector(state => state.crm.filterdCompanies)
  const [searchValueField, setSearchValueField] = useState(null)

  const changeSearch = e => {
    setSearchValueField(e.target.value)
  }

  useEffect(() => {
    dispatch(fetchCompanies())
  }, [dispatch])

  let mapCells = companies => {
    return companies.map(row => {
      return (
        <TableRow key={row.id}>
          <TableCell key={row.id + '_company'}>
            <strong>
              <Link to={`/crm/companies/${row.id}`}>{row.naambedrijf}</Link>
            </strong>
          </TableCell>
          <TableCell key={row.id + '_vat'}>
            {row.btwprefix + row.btwnummer}
          </TableCell>
          <TableCell key={row.id + '_list'}>
            <span className="list">
              {row.orgs.map(link => {
                return (
                  <span key={link.id}>
                    <strong>{link.organisatie}</strong>
                    <br />
                    {link.type}
                  </span>
                )
              })}
            </span>
          </TableCell>
          <TableCell key={row.id + '_detail'} style={{ textAlign: 'right' }}>
            <Link to={`/crm/companies/${row.id}`}>
              <Button variant="outlined">
                <ContactsOutlinedIcon /> &nbsp;DETAILFICHE
              </Button>
            </Link>
          </TableCell>
        </TableRow>
      )
    })
  }

  return (
    <div className="crmList">
      <div>
        <TextField
          margin="normal"
          fullWidth
          label={t('crm.search')}
          onChange={e => {
            dispatch(searchCompanies(e.target.value))
            changeSearch(e)
          }}
          autoFocus
          style={{ maxWidth: '300px' }}
        />
      </div>
      <Table style={{ width: '100%' }}>
        <TableHead>
          <TableRow>
            <TableCell key="company_name" style={{ fontSize: '1.2rem' }}>
              {t('crm.company.name')}
            </TableCell>
            <TableCell key="vat_number" style={{ fontSize: '1.2rem' }}>
              {t('crm.company.vat-number')}
            </TableCell>
            <TableCell key="org_list" className="orgList">
              &nbsp;
            </TableCell>
            <TableCell key="detail_fiche">&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!searchValueField ? (
            filterdCompanies.length !== 0 ? (
              mapCells(filterdCompanies)
            ) : (
              <TableRow>
                <TableCell colSpan={3}>
                  <div className="crm noEntries">
                    {t('crm.noSearchResults')}
                  </div>
                </TableCell>
              </TableRow>
            )
          ) : (
            mapCells(companies)
          )}
        </TableBody>
      </Table>
    </div>
  )
}
