import { fetchAPI, fetchGetAPI } from '../../../general/BuddyFuncs'
import { store } from '../../redux/configureStore'
import { stateName } from './employeePageReducer'

export const fetchNewEmployeesAction = () => {
  const token = store.getState().loginReducer.data.bearerToken

  const input = new FormData()

  input.append('divisions_id', 0)

  return {
    type: `${stateName}_GET`,
    payload: fetchGetAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/employees`,
      headers: {
        Authorization: token,
      },
      formData: input,
    }).then(r => {
      //fetch the roles
      return fetchAPI(
        `${process.env.REACT_APP_PUBLIC_URL}api/user/list-roles`,
        {
          Authorization: token,
        },
      ).then(roles => {
        return { ...r, roles: roles }
      })
    }),
  }
}
