import React from 'react'
import {
  Avatar,
  IconButton,
  Fab,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material'
import DescriptionIcon from '@material-ui/icons/Description'
import ArchiveIcon from '@material-ui/icons/Archive'
import EditIcon from '@material-ui/icons/Edit'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

export const ProjectItem = ({ project, onEdit, onArchive, onExport }) => {
  return (
    <ListItem
      className={`card projectItem`}
      style={{ opacity: project.archived ? 0.6 : 1 }}
    >
      <ListItemAvatar>
        <Avatar className={project.archived ? `inactive` : 'active'}>
          <DescriptionIcon fontSize="large" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        className={`title`}
        primary={project.title}
        secondary={
          project.archived
            ? project.subtitle + ` [gearchiveerd]`
            : project.subtitle
        }
      />
      <ListItemSecondaryAction>
        {!!project.archived && (
          <Fab
            style={{ marginRight: '1.6rem' }}
            color="primary"
            size="small"
            edge="end"
            aria-label="Exporteren"
            onClick={() => onExport(project)}
          >
            <CloudDownloadIcon fontSize="large" />
          </Fab>
        )}
        <Fab
          className={project.archived ? `transparant-20` : ``}
          color="primary"
          size="small"
          style={{ marginRight: '1.6rem' }}
          edge="end"
          aria-label="Archiveren"
          onClick={() => onArchive(project)}
        >
          <ArchiveIcon fontSize="large" />
        </Fab>
        <Fab
          color="primary"
          size="small"
          style={{ marginRight: 0 }}
          edge="end"
          aria-label="Naam wijzigen"
          onClick={() => onEdit(project)}
        >
          <EditIcon fontSize="large" />
        </Fab>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
