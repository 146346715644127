import React from 'react'
import InputMat from '../../../__core/components/InputMat'
import SelectMat from '../../../__core/components/SelectMat'
import ProfilePicUpload from '../../../__core/components/ProfilePicUpload'
import { useSelector } from 'react-redux'

const BaseInfo = props => {
  const profileInfo = useSelector(state => state.loginReducer.data)
  const languages = useSelector(
    state => state.overlayReducer.selectOptions.data.languages,
  )

  const handleChange = e => {
    props.setDisableSave(false)
  }

  const languageOptions = languages
    ? Object.entries(languages).map(([key, value]) => ({
        id: key,
        index: key,
        title: value,
      }))
    : null

  return (
    <div className="baseInfo column">
      <legend>Algemene informatie</legend>
      <ProfilePicUpload
        inputProps={{
          id: 'profilePic',
          name: 'profilePic',
          label: 'Profiel foto',
        }}
        prefill={{
          avatar: profileInfo.avatar,
        }}
      />
      <div className="inputs-2">
        <InputMat
          id="firstName"
          name="first_name"
          label="Voornaam"
          prefill={profileInfo.profile ? profileInfo.profile.first_name : null}
          onChange={e => handleChange(e)}
        />
        <InputMat
          id="lastName"
          name="last_name"
          label="Familienaam"
          prefill={profileInfo.profile ? profileInfo.profile.last_name : null}
          onChange={e => handleChange(e)}
        />
      </div>
      <p className="jobTitle">
        <span className="small">Functie</span>
        <strong>
          {profileInfo.profile ? profileInfo.profile.roles_name : ''}
        </strong>
      </p>
      <SelectMat
        id="language"
        name="prefered_language"
        label="Taal"
        items={languageOptions}
        hideFirstOptionEmpty
        prefill={
          profileInfo.profile ? profileInfo.profile.prefered_language : 'en'
        }
        onChange={e => handleChange(e)}
      />
    </div>
  )
}

export default BaseInfo
