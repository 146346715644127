import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'

export const EditProjectDialog = ({ open, handleClose, project, onSubmit }) => {
  const [name, setName] = useState('')

  useEffect(() => {
    if (project) setName(project.title)
    else setName('')
  }, [project])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Pas project aan</DialogTitle>
      <DialogContent style={{ paddingTop: '8px' }}>
        <TextField
          id="name"
          label="Project naam"
          variant="outlined"
          name="name"
          value={name}
          onChange={e => setName(e.target.value)}
          required
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSubmit({ name })}
          variant="contained"
          style={{
            margin: '0.8rem',
          }}
        >
          Opslaan
        </Button>
        <Button
          onClick={handleClose}
          style={{ margin: '0.8rem 1.6rem 0.8rem 0.8rem' }}
        >
          Annuleer
        </Button>
      </DialogActions>
    </Dialog>
  )
}
