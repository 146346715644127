import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import { Grid, List } from '@mui/material'
import { CreateProjectForm } from './CreateProjectForm'
import { archiveProject, editProject, exportProject } from './api'
import { ProjectItem } from './ProjectItem'
import { EditProjectDialog } from './EditProjectDialog'
import { overlayGetSelectOptions } from '../overlay/api'

export const ProjectSettings = () => {
  const [editingProject, setEditingProject] = useState(null)
  const userData = useSelector(state => state.loginReducer.data)
  const projectArray = useSelector(
    state => state.overlayReducer.selectOptions.data.projects || [],
  )

  const onExport = project => {
    exportProject(project, userData.bearerToken)
  }

  const onArchive = project => {
    archiveProject(project, userData.bearerToken).then(() =>
      overlayGetSelectOptions(),
    )
  }

  const onEdit = project => {
    editProject(
      {
        ...editingProject,
        title: project.name,
      },
      userData.bearerToken,
    )
      .then(() => overlayGetSelectOptions())
      .then(() => setEditingProject(null))
  }

  return (
    <div className="projectSettings">
      <Grid item xs={12} md={10} lg={8}>
        <CreateProjectForm />
        {projectArray.length === 0 ? (
          <CircularProgress />
        ) : (
          <div>
            <div className="legend">Projecten</div>
            <List dense>
              {projectArray.map(item => {
                if (item.id === -1) return
                return (
                  <ProjectItem
                    key={item.id}
                    project={item}
                    onEdit={setEditingProject}
                    onArchive={onArchive}
                    onExport={onExport}
                  />
                )
              })}
            </List>
          </div>
        )}
      </Grid>
      <EditProjectDialog
        open={!!editingProject}
        project={editingProject}
        handleClose={() => setEditingProject(null)}
        onSubmit={onEdit}
      />
    </div>
  )
}
