import React, { useEffect, useState } from 'react'
import { Switch, FormControlLabel, FormGroup } from '@mui/material'

const SwitchMat = props => {
  const [val, setVal] = useState(props.prefill)

  const changeInput = () => {
    const newVal = !val
    setVal(newVal)

    if (props.onChange) {
      props.onChange(newVal)
    }
  }

  useEffect(() => {
    setVal(props.prefill)
  }, [props.prefill])

  return (
    <div className="inputMat input">
      <FormGroup>
        <FormControlLabel
          label={props.label}
          control={
            <Switch
              id={props.id}
              name={props.name}
              size={props.size}
              checked={val}
              onChange={changeInput}
              onFocus={props.onFocus ? e => props.onFocus(e) : null}
              value={val}
            />
          }
        />
      </FormGroup>
    </div>
  )
}

export default SwitchMat
