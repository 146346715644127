import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import InputMat from '../../__core/components/InputMat'
import Button from '@mui/material/Button'
import { loginUser } from './api'
import { Link, Snackbar } from '@mui/material'
import { passwordReducerReset } from '../createPassword/api'
import { submitAccountPrefill } from '../forgotPassword/api'

const Login = () => {
  const [accountField, setAccountField] = useState('')
  const [checkLoginField, setCheckLoginField] = useState(false)
  const [checkPassField, setCheckPassField] = useState(false)
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationMessage, setNotificationMessage] = useState('')

  const history = useHistory()

  const { userData } = useSelector(state => {
    return {
      userData: state.loginReducer.data,
    }
  })

  const { passwordTokenInvalid, passwordUpdated } = useSelector(state => {
    return {
      passwordTokenInvalid: state.passwordRestReducer.data.invalid,
      passwordUpdated: state.passwordRestReducer.data.status,
    }
  })

  useEffect(() => {
    if (passwordTokenInvalid) {
      setNotificationMessage('Je kan met dit token geen wachtwoord aanpassen.')
      setOpenNotification(true)
      passwordReducerReset()
      setTimeout(function () {
        setOpenNotification(false)
      }, 3000)
    }
    if (passwordUpdated) {
      setNotificationMessage('Je wachtwoord is succesvol aangepast!')
      setOpenNotification(true)
      passwordReducerReset()
      setTimeout(function () {
        setOpenNotification(false)
      }, 3000)
    }
  }, [passwordTokenInvalid, passwordUpdated])

  useEffect(() => {
    if (typeof userData.required !== 'undefined') {
      setLoginErrorMessage(!!userData.showMessage ? userData.showMessage : '')

      setCheckLoginField(userData.required.includes('login'))
      setCheckPassField(userData.required.includes('pass'))
    }
  }, [userData])

  const trackAccountField = e => {
    hideLoginErrorMessage()

    setAccountField(e)
  }

  const hideLoginErrorMessage = () => {
    setCheckLoginField(false)
    setCheckPassField(false)
    setLoginErrorMessage('')
  }

  const handleForgotPassword = () => {
    if (accountField.includes('@')) {
      submitAccountPrefill(accountField)
      history.push(`reset-password`)
    } else history.push(`reset-password`)
  }

  const submitForm = e => {
    e.preventDefault()

    const formData = new FormData(e.target)
    loginUser(formData)
  }

  return (
    <div className="login">
      <form onSubmit={e => submitForm(e)}>
        <InputMat
          id={'username'}
          name={'login'}
          label={'Gebruikersnaam'}
          error={checkLoginField}
          onChange={trackAccountField}
        />
        <InputMat
          id={'passwoord'}
          name={'pass'}
          label={'Paswoord'}
          type="password"
          onChange={hideLoginErrorMessage}
          error={checkPassField}
          errorText={loginErrorMessage}
        />
        <Button type="submit" variant="contained">
          login
        </Button>
      </form>
      <Link
        component="button"
        variant="body2"
        onClick={handleForgotPassword}
        className={'forgotPassword'}
      >
        Paswoord vergeten?
      </Link>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={openNotification}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{notificationMessage}</span>}
      />
    </div>
  )
}

export default Login
