import React, { useRef } from 'react'
import { overlayToggleOpen } from '../../overlay/api'
import { Fab } from '@mui/material'
import {
  addTimeDiff,
  addLeadingZeros,
  addLeadingZerosToTime,
} from '../../../../general/BuddyFuncs'

const Entry = props => {
  const columnAllowValues = ['time', 'project', 'task', 'desc']
  const entrie = props.entry
  const id = props.id

  const cardRef = useRef(null)

  const updateEntry = e => {
    //open the edit/add entry field
    overlayToggleOpen(true, 'timesheet', 'Entry aanpassen', createPrefills())
  }

  const createPrefills = () => {
    const prefills = props.entry.fields ? { ...props.entry.fields } : {}

    if (prefills.time !== undefined) {
      prefills.time = {
        date: props.date,
        start: props.entry.start,
        end: props.entry.end,
      }
    }

    prefills.id = id

    return prefills
  }

  const calcTotalTime = () => {
    const totalTime = addTimeDiff(props.entry.start, props.entry.end)

    return (
      <>
        {addLeadingZeros(totalTime.hours)}:{addLeadingZeros(totalTime.minutes)}
      </>
    )
  }

  return (
    <>
      <tr
        className={props.entry.readonly ? 'card' : 'card hasClick'}
        ref={cardRef}
      >
        {Object.keys(entrie.fields).map((key, index) => {
          const field = entrie.fields[key]

          return !columnAllowValues.includes(key) ? null : (
            <td
              key={`entrieFieldIndex${index}`}
              onClick={e => (entrie.readonly ? false : updateEntry(e))}
            >
              {key === 'time' ? (
                <div className="hourEntry">
                  <div className="fromTo">
                    <p>{addLeadingZerosToTime(props.entry.start)}</p>
                    <p>{addLeadingZerosToTime(props.entry.end)}</p>
                  </div>
                  <p className="accentColor equals">=</p>
                  <p> {calcTotalTime()}</p>
                </div>
              ) : field.subtitle !== undefined ? (
                <p className="subTitle">
                  <span>{field.title}</span>
                  <span>{field.subtitle}</span>
                </p>
              ) : typeof field === 'string' ? (
                field
              ) : null}
            </td>
          )
        })}
        {props.entry.readonly
          ? null
          : props.entryBtns.map(btn => {
              return (
                <td key={`entryBtn${btn.title}${id}`} className="btnHolder">
                  <Fab
                    color="secondary"
                    size="small"
                    onClick={e => btn.onClick(e, entrie.id)}
                  >
                    {btn.icon}
                  </Fab>
                </td>
              )
            })}
      </tr>
      <tr className="spacer"></tr>
    </>
  )
}

export default Entry
