import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import AddIcon from '@material-ui/icons/Add'
import { TextField, Fab, Button } from '@mui/material'
import SelectMat from '../../__core/components/SelectMat'
import { overlayGetSelectOptions } from '../overlay/api'
import { createProject } from './api'

export const CreateProjectForm = () => {
  const userData = useSelector(state => state.loginReducer.data)
  const [projectName, setProjectName] = useState('')
  const [selectedOrg, setSelectedOrg] = useState('')

  const divisionOptions = useSelector(state => {
    const divisions = state.overlayReducer.selectOptions.data.divisions || {}

    return Object.keys(divisions).map(key => ({
      id: key,
      index: key,
      title: divisions[key],
    }))
  })

  const onSubmit = e => {
    e.preventDefault()
    const divisionID = selectedOrg
    const name = projectName

    if (!divisionID || !name) return

    return createProject({ divisionID, name }, userData.bearerToken).then(
      () => {
        setProjectName('')
        setSelectedOrg('')
        overlayGetSelectOptions()
      },
    )
  }

  return (
    <form
      onSubmit={onSubmit}
      style={{
        alignItems: 'flex-start',
      }}
    >
      <legend>Nieuw project toevoegen</legend>
      <div className="createProjectForm">
        <TextField
          className="inputMat input"
          variant="outlined"
          id="projectName"
          name="name"
          label="Project naam"
          value={projectName}
          onChange={e => setProjectName(e.target.value)}
          style={{ margin: '8px 1.6rem 8px 0', flex: 2 }}
          required
        />
        <SelectMat
          id="division"
          name="division"
          label="Organisatie"
          items={divisionOptions}
          value={selectedOrg}
          onChange={setSelectedOrg}
          style={{ margin: '8px 1.6rem 8px 0', flex: 2 }}
          required
        />
        <Fab
          size="small"
          color="primary"
          aria-label="add"
          type="submit"
          onClick={onSubmit}
        >
          <AddIcon fontSize="large" />
        </Fab>
      </div>
    </form>
  )
}
