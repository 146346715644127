import React, { useState } from 'react'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  disabled: {
    opacity: 0.5,
  },
})

const SelectMat = props => {
  const [val, setVal] = useState(
    props.prefill !== undefined && props.prefill !== null
      ? props.prefill
      : !props.hideFirstOptionEmpty
      ? 'none'
      : props.items[0].index,
  )

  const checkIfoptionExists = val => {
    if (val !== null && val !== 'none') {
      const compatibleOptions = props.items.filter(item => {
        return item.index === val
      })

      if (compatibleOptions.length === 0) {
        //not present
        if (props.hideFirstOptionEmpty) {
          return undefined
        } else {
          return 'none'
        }
      }
      return val
    } else {
      return 'none'
    }
  }

  const handleChange = e => {
    const myValue = checkIfoptionExists(e.target.value)

    setVal(myValue)

    if (props.onChange) {
      props.onChange(myValue)
    }
  }

  const classes = useStyles()
  const valueProp = props.value === undefined ? val : props.value

  return (
    <div
      className={`selectMat input ${props.className || ''}`}
      style={props.style}
    >
      <FormControl
        variant="outlined"
        size={props.size}
        required={props.required !== null && props.required !== undefined}
        className={props.disabled ? `${classes.disabled}` : ''}
        error={props.error ?? false}
      >
        <InputLabel shrink={!!valueProp} id={`${props.id}-label`}>
          {props.label}
        </InputLabel>
        <Select
          input={
            !!valueProp ? (
              <OutlinedInput notched label={`${props.label}`} />
            ) : undefined
          }
          labelId={`${props.id}-label`}
          id={props.id}
          name={props.name}
          value={!!valueProp ? valueProp : ''}
          onChange={handleChange}
          className={`${classes.root}`}
          disabled={props.disabled}
        >
          {!props.hideFirstOptionEmpty && props.items.length == 0 ? (
            <MenuItem
              className={classes.root}
              key={`${props.name}-none`}
              value="none"
            >
              <div className="menuSpan">
                {props.emptyText ? props.emptyText : 'None'}
              </div>
            </MenuItem>
          ) : null}
          {props.items
            ? props.items.map(item => {
                return (
                  <MenuItem
                    className={classes.root}
                    key={`${props.name}-${item.id}`}
                    value={item.index}
                  >
                    <div className="menuSpan">
                      {item.icon ? (
                        <div className="menuIcon">{item.icon}</div>
                      ) : null}
                      <span className="menuSpanTitle">
                        <span className="menuSpan">{item.title}</span>
                        {item.subTitle ? (
                          <span className="menuSpan small">
                            {item.subTitle}
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </MenuItem>
                )
              })
            : null}
        </Select>
      </FormControl>
    </div>
  )
}

export default SelectMat
