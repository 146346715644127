import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateContact } from '../crmSlice.js'
import { Item } from '../detail/Item'
import { ContactForm } from '../forms/ContactForm'

export function CreateContact() {
  const history = useHistory()
  const dispatch = useDispatch()

  const { companyId } = useParams()

  const onSubmit = async values => {
    await dispatch(
      updateContact({ id: 0, crm_bedrijf_id: companyId, ...values }),
    )
    history.push(`/crm/companies/${companyId}`)
  }
  const onCancel = () => {
    history.goBack()
  }

  return (
    <Item elevation={3}>
      <ContactForm onSubmit={onSubmit} onCancel={onCancel} />
    </Item>
  )
}
