import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Stack,
} from '@mui/material'
import { Close, Add } from '@mui/icons-material'
import { Item } from './Item'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const Links = ({ links, onDelete, onAdd, addFields }) => {
  const [showAdd, setShowAdd] = useState(false)
  const { t } = useTranslation()

  const { handleSubmit, reset, control } = useForm({})

  const onSubmit = async values => {
    await onAdd(values)
    reset({})
    setShowAdd(false)
  }

  return (
    <>
      <Item elevation={3}>
        <Stack spacing={1} flex={1}>
          <Stack key="l-0">
            <span className="cardTitle">DECLARATIE CMS KOPPELING</span>
          </Stack>
          {links && links.length ? (
            links.map(link => (
              <Stack
                key={link.id}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <span>
                  <span style={{ textTransform: 'uppercase' }}>
                    {link.organisation}
                  </span>{' '}
                  &mdash; {link.type}
                </span>
                <IconButton
                  aria-label="delete"
                  color="error"
                  onClick={() => onDelete(link)}
                >
                  <Close fontSize="inherit" />
                </IconButton>
              </Stack>
            ))
          ) : (
            <span>{t('general.no-data')}</span>
          )}
          <hr />
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => setShowAdd(true)}
          >
            <Add /> {t('general.addLinkage')}
          </Button>
        </Stack>
      </Item>

      <Dialog open={showAdd} fullWidth>
        <DialogTitle>{t('crm.add-link-title')}</DialogTitle>
        <DialogContent style={{ paddingTop: '8px' }}>
          <Stack
            spacing={2}
            component="form"
            alignItems="stretch"
            onSubmit={handleSubmit(onSubmit)}
          >
            {addFields.map(fieldDef => (
              <Controller
                key={fieldDef.name}
                name={fieldDef.name}
                control={control}
                render={({ field }) => (
                  <FormControl>
                    <InputLabel>{fieldDef.label}</InputLabel>
                    <Select
                      label={fieldDef.label}
                      variant="outlined"
                      type="text"
                      {...field}
                    >
                      {Object.keys(fieldDef.options).map(key => (
                        <MenuItem key={key} value={fieldDef.options[key]}>
                          {fieldDef.options[key]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />
            ))}
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="contained" color="primary" type="submit">
                {t('general.add')}
              </Button>
              <Button onClick={() => setShowAdd(false)}>
                {t('general.cancel')}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}
