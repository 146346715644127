import React, { useState } from 'react'
import Switch from '@mui/material/Switch'
import { overlayToggleOpen } from '../../overlay/api'
import { employeeUpdateStatus } from '../api'

const Employee = props => {
  const [hasAccess, sethasAccess] = useState(props.active === '1')

  const writeDivisions = () => {
    if (props.divisions) {
      return props.divisions.map(divi => {
        return (
          <span key={`diviName${props.user_id}${divi.name}`}>{divi.name}</span>
        )
      })
    }

    return ''
  }

  const handleChange = e => {
    //update localy
    sethasAccess(e.target.checked)

    //post data
    employeeUpdateStatus(props.users_id, e.target.checked ? 1 : 0)
  }

  const handleEditEmployee = e => {
    const target = e.target

    if (
      target.nodeName !== 'INPUT' &&
      !target.classList.contains('btnHolder')
    ) {
      overlayToggleOpen(true, 'employee', 'Medewerker aanpassen', props)
    }
  }

  const userCanEdit = () => {
    return !(
      parseInt(props.userType) < parseInt(props.roles_id) ||
      parseInt(props.roles_id) === 0
    )
  }

  return (
    <>
      <tr
        className="card employee hasClick"
        onClick={e => handleEditEmployee(e)}
      >
        <td>
          <span className="subTitle">
            <span>{`${props.first_name} ${props.last_name}`}</span>
            <span>{props.job_title}</span>
          </span>
        </td>
        <td>
          <span className="list">{writeDivisions()}</span>
        </td>
        <td>{props.email}</td>
        <td>{props.gsm}</td>
        <td className="btnHolder">
          <Switch
            checked={hasAccess}
            onChange={handleChange}
            color="primary"
            name="access"
            inputProps={{ 'aria-label': 'access' }}
            disabled={userCanEdit()}
          />
        </td>
      </tr>
      <tr className="spacer"></tr>
    </>
  )
}

export default Employee
