import React from 'react'
import { useSelector } from 'react-redux'
import {
  writeDate,
  diffTimeMinutes,
  map,
  isToday,
  minutesToTimeStamp,
  addLeadingZerosToTime,
} from '../../../../general/BuddyFuncs'
import DayMeter from '../../dayMeter/DayMeter'
import DoneRoundedIcon from '@material-ui/icons/DoneRounded'
import MoreHorizRoundedIcon from '@material-ui/icons/MoreHorizRounded'
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded'
import { timesheetFilterSetFomTo } from '../../timesheetFilter/api'

const Entry = props => {
  const breakTime = useSelector(state => state.timesheetReducer.data.break)

  const availableMinutes =
    diffTimeMinutes(props.entry.startTime, props.entry.endTime) - breakTime

  const calcTotalMinutes = () => {
    let totalCurrent = 0

    if (props.entry.entries) {
      props.entry.entries.forEach(entry => {
        totalCurrent += diffTimeMinutes(entry.start, entry.end)
      })
    }
    return totalCurrent
  }

  const calcStatus = () => {
    let percent = 0

    //taly everything up
    if (props.entry.entries) {
      //map total current to a percentage
      percent = Math.ceil(map(calcTotalMinutes(), 0, availableMinutes, 0, 100))
    } else {
      percent = 0
    }

    if (percent > 100) {
      //goal reached
      return (
        <button className="ghostBtn squareBtn roundBtn iconBtn">
          <DoneRoundedIcon />
        </button>
      )
    } else if (isToday(props.entry.date)) {
      //is ongoing
      return (
        <button className="ghostBtn squareBtn roundBtn iconBtn">
          <MoreHorizRoundedIcon />
        </button>
      )
    } else {
      //error
      return (
        <button className="ghostBtn squareBtn roundBtn iconBtn errorBtn">
          <ErrorRoundedIcon />
        </button>
      )
    }
  }

  const writeWeekday = () => {
    const date = new Date(props.entry.date)

    const dagen = [
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag',
      'Zondag',
    ]

    return dagen[date.getDay() - 1]
  }

  const totalHours = () => {
    const total = minutesToTimeStamp(calcTotalMinutes())

    return addLeadingZerosToTime(`${total.hours}:${total.minutes}`)
  }

  const handleClickEntry = e => {
    e.preventDefault()

    //set filter to date of this entry
    timesheetFilterSetFomTo({
      startDate: props.entry.date,
      endDate: props.entry.date,
    })
  }

  return (
    <>
      <tr className="card hasClick" onClick={handleClickEntry}>
        {props.entry ? (
          <>
            <td className="status">
              <div>{calcStatus()}</div>
            </td>
            <td>{writeWeekday()}</td>
            <td>{writeDate(props.entry.date)}</td>
            <td>{totalHours()}</td>
            <td className="fillRest">
              <DayMeter
                orientation="horizontal"
                content={{ ...props.entry, break: breakTime }}
              />
            </td>
          </>
        ) : null}
      </tr>
      <tr className="spacer"></tr>
    </>
  )
}

export default Entry
