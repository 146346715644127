import { fetchGetAPI, fetchPostJsonAPI } from '../../../general/BuddyFuncs'

export const createProject = ({ divisionID, name }, bearerToken) => {
  return fetchPostJsonAPI({
    url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/project`,
    method: 'PUT',
    headers: {
      Authorization: bearerToken,
    },
    json: {
      divisionID,
      projectID: 0,
      name,
    },
  }).then(v => {
    if (!v.success) throw Error('creation failed')
    return v.record
  })
}

export const editProject = (project, bearerToken) => {
  return fetchPostJsonAPI({
    url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/project`,
    method: 'PUT',
    headers: {
      Authorization: bearerToken,
    },
    json: {
      divisionID: project.divisions_id,
      projectID: project.id,
      name: project.title,
    },
  }).then(v => {
    if (!v.success) throw Error('update failed')
    return v.record
  })
}

export const archiveProject = (project, bearerToken) => {
  return fetchPostJsonAPI({
    url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/project`,
    method: 'PUT',
    headers: {
      Authorization: bearerToken,
    },
    json: {
      divisionID: project.divisions_id,
      projectID: project.id,
      archive: project.archived ? 0 : 1,
    },
  }).then(v => {
    if (!v.success) throw Error('archive failed')
    return v.record
  })
}

export const exportProject = (project, bearerToken) => {
  const formData = new FormData()
  formData.append('division_id', project.divisions_id)
  formData.append('project_id', project.id)
  return fetchGetAPI({
    url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/export`,
    method: 'GET',
    headers: {
      Authorization: bearerToken,
    },
    formData,
  }).then(r => {
    if (r.success) {
      downloadFile(r.content, `project-${project.title}-${project.id}`)
    }
  })
}

const downloadFile = (content, nameAffix) => {
  const downloadLink = document.createElement('a')
  const blob = new Blob(['\ufeff', content])
  const url = URL.createObjectURL(blob)

  //add hide class
  downloadLink.classList.add('hide')

  //add attributes to link
  downloadLink.href = url
  downloadLink.download = `export-${nameAffix}.csv`

  //add link to body and remove it after download
  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
}
