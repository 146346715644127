import React from 'react'
import {
  addDays,
  diffTimeMinutes,
  getNumberOfWeek,
  minutesToTimeStamp,
  writeDate,
  addLeadingZerosToTime,
} from '../../../../general/BuddyFuncs'
import Entry from './Entry'

const Week = props => {
  const createHeader = buttons => {
    const headers = props.headers.map(header => {
      return <td key={`headers${header}`}>{header}</td>
    })

    for (let i = 0; i < buttons; i++) {
      headers.push(<td key={`headerBtn${i}`}></td>)
    }

    return headers
  }

  const createFromToDate = () => {
    let dateString = ''

    if (props.entries[0]) {
      const startDate = new Date(props.entries[0].date)
      const endDate = addDays(startDate, 4)
      dateString = `${writeDate(startDate)} - ${writeDate(endDate)}`
    }

    return dateString
  }

  const calcTotalHours = () => {
    let minutes = 0

    props.entries.forEach(entry => {
      if (entry.entries) {
        entry.entries.forEach(hourEntry => {
          minutes += diffTimeMinutes(hourEntry.start, hourEntry.end)
        })
      }
    })

    const timeStamp = minutesToTimeStamp(minutes)

    return addLeadingZerosToTime(`${timeStamp.hours}:${timeStamp.minutes}`)
  }

  return (
    <div className="week">
      <header className="weekHeader">
        <div className="title">
          <h3>Week {props.weekNumber}</h3>
          <p>{createFromToDate()}</p>
        </div>
        <div className="hoursTotal">
          <p className="small">Uren</p>
          <p>{calcTotalHours()}</p>
        </div>
      </header>
      {props.entries.length > 0 ? (
        <table className="entries">
          <thead>
            <tr>{createHeader(0)}</tr>
          </thead>
          <tbody>
            {props.entries.map((entry, id) => {
              return (
                <Entry
                  entry={entry}
                  id={id}
                  key={`entrieKey${id}${entry.date}`}
                />
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="noEntries">No entries</div>
      )}
    </div>
  )
}

export default Week
