import React, { useState } from 'react'
import TextField from '@mui/material/TextField'

const InputMat = props => {
  const [val, setVal] = useState(null)

  const changeInput = e => {
    setVal(e.target.value)

    if (props.onChange) {
      if (props.type !== 'file') {
        props.onChange(e.target.value)
      } else {
        props.onChange(e.target.files)
      }
    }
  }

  return (
    <div className="inputMat input">
      <TextField
        id={props.id}
        name={props.name}
        label={props.label}
        type={props.type}
        variant="outlined"
        fullWidth
        value={val ? val : props.prefill ? props.prefill : ''}
        onChange={changeInput}
        size={props.size}
        onFocus={props.onFocus ? e => props.onFocus(e) : null}
        required={props.required !== null && props.required !== undefined}
        {...props.inputProps}
        error={props.error}
        helperText={props.errorText}
      />
    </div>
  )
}

export default InputMat
