import React, { useMemo, useState } from 'react'
import InputMat from '../../../__core/components/InputMat'
import SelectMat from '../../../__core/components/SelectMat'
import SwitchMat from '../../../__core/components/SwitchMat'
import WorkRoundedIcon from '@material-ui/icons/WorkRounded'
import { useSelector } from 'react-redux'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import { fetchPostJsonAPI } from '../../../../general/BuddyFuncs'
import { overlayToggleOpen } from '../api'
import { employeeFetchAll } from '../../employeePage/api'
import { Typography, Button, Fab } from '@mui/material'
import AddIcon from '@material-ui/icons/Add'

const EmployeeForm = props => {
  const { profileInfo, roles, token, userRole, prefill, overLayOptions } =
    useSelector(state => {
      return {
        profileInfo: state.loginReducer.data,
        roles: state.employeePageReducer.data.roles,
        token: state.loginReducer.data.bearerToken,
        userRole: state.loginReducer.data.profile.roles_id,
        prefill: state.overlayReducer.prefill,
        overLayOptions: state.overlayReducer.selectOptions.data,
      }
    })

  const divisionOptions = useMemo(() => {
    if (overLayOptions.divisions)
      return Object.keys(overLayOptions.divisions).map(key => ({
        index: key,
        id: key,
        title: overLayOptions.divisions[key],
      }))
    return [{ index: 'none', title: '' }]
  }, [overLayOptions])

  const roleOptions = useMemo(() => {
    if (roles) {
      return Object.keys(roles.roles)
        .map(roleKey => ({
          index: roleKey,
          id: roleKey,
          title: roles.roles[roleKey],
        }))
        .reverse()
    }
    return [{ index: 'none', title: '' }]
  }, [roles])

  const [selectedOption, setSelectedOption] = useState({
    divi: profileInfo.profile
      ? divisionOptions.find(
          d => d.id === profileInfo.profile.divisions[0].divisions_id,
        )
      : {},
    role: roles
      ? Object.keys(roles.roles)[Object.keys(roles.roles).length - 1]
      : '',
  })
  const [selectedDivis, setSelectedDivis] = useState(
    prefill.divisions
      ? prefill.divisions.map(divi => ({
          id: divi.divisions_id,
          index: divi.divisions_id,
          title: divi.name,
          role: {
            index: divi.roles_id,
            title: roles ? roles.roles[divi.roles_id] : '',
          },
        }))
      : [],
  )

  const languageOptions = overLayOptions.languages
    ? Object.entries(overLayOptions.languages).map(([key, value]) => ({
        id: key,
        index: key,
        title: value,
      }))
    : null

  const [selectedDivisAlert, setSelectedDivisAlert] = useState(false)

  const submitForm = e => {
    e.preventDefault()

    if (selectedDivis.length === 0) {
      setSelectedDivisAlert(true)
      return
    }
    //close the window
    overlayToggleOpen(false)

    const formData = new FormData(e.target)
    formData.append('crm_access', crmAccess ? '1' : '0')
    console.log(crmAccess ? '1' : '0')
    fetchPostJsonAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/user-record`,
      json: Object.fromEntries(formData),
      method: 'PUT',
      headers: {
        Authorization: token,
      },
    }).then(r => {
      if (r.success) {
        //link the selected divisions
        selectedDivis.forEach(divi => {
          fetchPostJsonAPI({
            url: `${process.env.REACT_APP_PUBLIC_URL}api/user/division`,
            json: {
              users_id: r.users_id,
              divisions_id: divi.index,
              roles_id: divi.role.index,
            },
            method: 'PUT',
            headers: {
              Authorization: token,
            },
          }).then(() => {
            //refresh the employee list
            employeeFetchAll()
          })
        })
      }
    })
  }

  const handleAddOrginization = e => {
    e.preventDefault()

    //get added divi
    const { divi: divisionId, role: roleId } = selectedOption

    //check if the divi is not already in the list
    const isPresent = selectedDivis.some(divi => divi.id == divisionId)

    if (divisionId && !isPresent) {
      const division = divisionOptions.find(d => d.id === divisionId)
      const role = roleOptions.find(r => r.id === roleId)
      //add option to the list
      if (division !== undefined) {
        setSelectedDivis(state => [
          ...state,
          {
            id: division.id,
            index: division.index,
            title: division.title,
            role: {
              index: role.index,
              title: role.title,
            },
          },
        ])
      }
    }
  }

  const handleUpdateSelectedoptions = (value, name) => {
    const newSelected = {
      ...selectedOption,
    }

    newSelected[name] = value

    setSelectedOption(newSelected)
  }

  const handleRemoveOrginization = (e, index) => {
    e.preventDefault()

    //remove localy
    setSelectedDivis(
      selectedDivis.filter(divi => parseInt(divi.index) !== parseInt(index)),
    )

    //unlink in the database
    if (prefill.users_id) {
      fetchPostJsonAPI({
        url: `${process.env.REACT_APP_PUBLIC_URL}api/user/division`,
        method: 'DELETE',
        headers: {
          Authorization: token,
        },
        json: {
          users_id: prefill.users_id,
          divisions_id: index,
        },
      })
    }
  }

  const [crmAccess, setCrmAccess] = useState()
  const handleChangeCRMAccess = value => {
    setCrmAccess(value)
  }

  return (
    <form className="employeeForm" onSubmit={e => submitForm(e)}>
      {prefill.users_id ? (
        <input type="hidden" name="users_id" value={prefill.users_id} />
      ) : null}
      <div className="formWrapper">
        <div className="columnHolder">
          <div className="baseInfo column">
            <h4 className="title">Algemene informatie</h4>
            <div className="inputs-2">
              <InputMat
                id="firstName"
                name="first_name"
                label="Voornaam"
                required
                prefill={prefill.first_name}
              />
              <InputMat
                id="lastName"
                name="last_name"
                label="Familienaam"
                required
                prefill={prefill.last_name}
              />
            </div>
            <InputMat
              id="jobTitle"
              name="job_title"
              label="Functie"
              required
              prefill={prefill.job_title}
            />
            <SelectMat
              id="language"
              name="prefered_language"
              label="Taal"
              items={languageOptions}
              hideFirstOptionEmpty
              prefill={
                prefill.prefered_language ? prefill.prefered_language : 'en'
              }
            />
            {/*
            <FormControlLabel
              control={
                <Switch
                  checked={hasAccess}
                  onChange={handleChangeAccess}
                  name="access"
                  color="primary"
                />
              }
              label="Heeft toegang tot de applicatie?"
            />
            */}

            {userRole <= 2 && userRole > 0 ? (
              <SwitchMat
                label="CRM access"
                name="crm_access"
                prefill={prefill.crm_access ?? false}
                onChange={handleChangeCRMAccess}
              />
            ) : null}
          </div>
          <div className="column">
            <h4 className="title">Contact informatie</h4>
            <InputMat
              id="email"
              name="email"
              label="E-mail"
              required
              prefill={prefill.email}
            />
            <InputMat id="gsm" name="gsm" label="GSM" prefill={prefill.gsm} />
            <InputMat
              id="tel"
              name="tel"
              label="Telefoon"
              prefill={prefill.tel}
            />
          </div>
        </div>

        <div className="organizations addOrganizations">
          <h4 className="title">Organisaties</h4>
          <div className="addOrganizationsInputs">
            <SelectMat
              id="orginization"
              label="Organisatie(s)"
              items={divisionOptions}
              onChange={division => {
                setSelectedOption(state => ({ ...state, divi: division }))
              }}
            />
            <SelectMat
              id="role"
              label="Rol"
              items={roleOptions}
              hideFirstOptionEmpty
              onChange={e => handleUpdateSelectedoptions(e, 'role')}
            />
            <Fab
              size="small"
              color="primary"
              aria-label="add"
              type="submit"
              onClick={e => handleAddOrginization(e)}
            >
              <AddIcon fontSize="large" />
            </Fab>
          </div>
          <div className="organizationsHolder">
            {selectedDivis.length > 0 ? (
              selectedDivis.map(divi => {
                return (
                  <div
                    className="card organisationCard"
                    key={`userpofile${divi.title}${divi.index}`}
                  >
                    <div className="iconHolder">
                      <WorkRoundedIcon fontSize="large" />
                    </div>
                    <div>
                      <h4>{divi.title}</h4>
                      <p className="small">{divi.role.title}</p>
                    </div>
                    <button
                      className="btn iconBtn roundBtn"
                      onClick={e => handleRemoveOrginization(e, divi.index)}
                    >
                      <DeleteRoundedIcon />
                    </button>
                  </div>
                )
              })
            ) : (
              <Typography
                color={selectedDivisAlert ? 'error' : 'primary'}
                variant="subtitle1"
              >
                Je moet minstens 1 organisatie aanduiden.
              </Typography>
            )}
          </div>
        </div>
      </div>
      <Button type="submit" variant="contained">
        {props.submitText ? props.submitText : 'Opslaan'}
      </Button>
    </form>
  )
}

export default EmployeeForm
