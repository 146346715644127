import React from 'react'
import { useSelector } from 'react-redux'
import InputMat from '../../../__core/components/InputMat'

const ContactInfo = props => {
  const profileInfo = useSelector(state => state.loginReducer.data)

  const handleChange = e => {
    props.setDisableSave(false)
  }

  return (
    <div className="contactInfo column">
      <legend>Contact informatie</legend>
      <InputMat
        id="email"
        name="email"
        label="Email"
        type="E-mail"
        prefill={profileInfo.profile ? profileInfo.profile.email : null}
        onChange={e => handleChange(e)}
      />
      <InputMat
        id="gsm"
        name="gsm"
        label="GSM"
        type="tel"
        prefill={profileInfo.profile ? profileInfo.profile.gsm : null}
        onChange={e => handleChange(e)}
      />
      <InputMat
        id="tel"
        name="tel"
        label="Telefoon"
        type="tel"
        prefill={profileInfo.profile ? profileInfo.profile.tel : null}
        onChange={e => handleChange(e)}
      />
    </div>
  )
}

export default ContactInfo
