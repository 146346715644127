import { Avatar } from '@mui/material'
import React, { useState } from 'react'
import InputMat from './InputMat'
import { makeStyles } from '@mui/styles'
import { fetchPostAPI } from '../../../general/BuddyFuncs'
import { useSelector } from 'react-redux'

const ProfilePicUpload = props => {
  const token = useSelector(state => state.loginReducer.data.bearerToken)

  const [avatar, setAvatar] = useState(
    props.prefill ? props.prefill.avatar : '',
  )

  const useStyles = makeStyles(theme => ({
    avatar: {
      width: '8rem',
      height: '8rem',
      backgroundColor: theme.palette.primary.main,
    },
  }))

  const classes = useStyles()

  const handleChangeProfilePic = input => {
    //set avatar localy
    setAvatar(URL.createObjectURL(input[0]))

    const formData = new FormData()

    formData.append('avatar', input[0], input[0].name)

    //upload new profile picture
    fetchPostAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/avatar`,
      headers: {
        Authorization: token,
      },
      formData: formData,
      method: 'POST',
    })
  }

  return (
    <div className="profilePicUpload input">
      <div className="avatarHolder">
        <Avatar className={classes.avatar} alt="profilepicture" src={avatar} />
      </div>
      <InputMat
        {...props.inputProps}
        type="file"
        inputProps={{
          InputLabelProps: {
            shrink: true,
          },
        }}
        onChange={input => handleChangeProfilePic(input)}
      />
    </div>
  )
}

export default ProfilePicUpload
