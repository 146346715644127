import { combineReducers } from 'redux'
import timesheetComp from '../components/timesheet/index'
import overlayComp from '../components/overlay/index'
import typeSelectorComp from '../components/typeSelector/index'
import timesheetFilterComp from '../components/timesheetFilter/index'
import loginComp from '../components/login/index'
import passwordComp from '../components/createPassword/index'
import employeePageComp from '../components/employeePage/index'
import forgotPasswordComp from '../components/forgotPassword/index'
import crmReducer from '../components/crm/crmSlice'

const createRootReducer = () =>
  combineReducers({
    crm: crmReducer,
    timesheetReducer: timesheetComp.timesheetReducer,
    overlayReducer: overlayComp.overlayReducer,
    timesheetFilterReducer: timesheetFilterComp.timesheetFilterReducer,
    typeSelectorReducer: typeSelectorComp.typeSelectorReducer,
    loginReducer: loginComp.loginReducer,
    passwordRestReducer: passwordComp.createPasswordReducer,
    employeePageReducer: employeePageComp.employeePageReducer,
    forgotPasswordComp: forgotPasswordComp.forgotPasswordReducer,
  })

export default createRootReducer
