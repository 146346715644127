import { fetchGetAPI } from '../../../general/BuddyFuncs'
import { store } from '../../redux/configureStore'
import { stateName } from './timesheetReducer'
import { reset } from '../../redux/configureStore'

export const resetAction = () => {
  return { type: `${stateName}${reset}`, payload: {} }
}

export const fetchNewEntriesAction = input => {
  const token = store.getState().loginReducer.data.bearerToken
  return {
    type: `${stateName}_GET`,
    payload: fetchGetAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/list`,
      headers: {
        Authorization: token,
      },
      formData: input,
    }),
  }
}
