import React, { useEffect, useMemo, useState } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Button, Alert } from '@mui/material'
import SelectMat from '../../../__core/components/SelectMat'
import TimeSelect from '../../../__core/components/TimeSelect'
import DateSelect from '../../../__core/components/DateSelect'
import InputMat from '../../../__core/components/InputMat'
import { useSelector } from 'react-redux'
import {
  addLeadingZeros,
  addLeadingZerosToTime,
  fetchPostJsonAPI,
} from '../../../../general/BuddyFuncs'
import { overlayToggleOpen } from '../api'
import { timesheetFetchNewData } from '../../timesheet/api'
import { format, parse } from 'date-fns'
import SwitchMat from '../../../__core/components/SwitchMat'

const TimesheetEntryForm = props => {
  const selectOptions = useSelector(
    state => state.overlayReducer.selectOptions.data,
  )
  const filteringRange =
    useSelector(state => state.timesheetFilterReducer.fromTo) || {}
  const prefill = useSelector(state => state.overlayReducer.prefill)
  const userData = useSelector(state => state.loginReducer.data)

  const orgPrefillId = useMemo(() => {
    if (!prefill || !prefill.project) return

    const division = prefill.project.subtitle

    const key = Object.keys(selectOptions.linked_divisions || {}).find(
      k => selectOptions.linked_divisions[k] === division,
    )

    if (key) return key
    return null
  }, [selectOptions, prefill])

  const [selectedOrg, setSelectedOrg] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [selectedTask, setSelectedTask] = useState(null)
  const [selectedStartDay, setSelectedStartDay] = useState(
    filteringRange.startDate,
  )
  const [selectedEndDay, setSelectedEndDay] = useState(filteringRange.endDate)

  const [validation, setValidation] = useState({})

  const divisionOptions = useMemo(() => {
    return Object.keys(selectOptions.linked_divisions).map(key => ({
      index: key,
      id: key,
      title: selectOptions.linked_divisions[key],
    }))
  }, [selectOptions])

  const projectOptions = useMemo(() => {
    return selectOptions.projects
      .filter(
        project =>
          project.archived === 0 &&
          (project.divisions_id === 0 ||
            project.divisions_id === parseInt(selectedOrg)),
      )
      .map(project => ({ ...project, index: project.id }))
  }, [selectOptions, selectedOrg])

  const taskOptions = useMemo(() => {
    return selectOptions.tasks
      .filter(
        task =>
          task.divisions_id === 0 ||
          task.divisions_id === parseInt(selectedOrg),
      )
      .map(task => ({ ...task, index: task.id }))
  }, [selectOptions, selectedOrg])

  const timePlaceholder = (date, time) => {
    const dateTime = { date, time }
    const now = new Date()

    if (!dateTime.date) {
      //undefined so pic today
      dateTime.date = `${addLeadingZeros(now.getFullYear())}-${addLeadingZeros(
        now.getMonth() + 1,
      )}-${addLeadingZeros(now.getDate())}`
    }

    if (!dateTime.time) {
      //undefined so pic today
      dateTime.time = addLeadingZerosToTime(
        `${now.getHours()}:${now.getMinutes()}`,
      )
    } else {
      dateTime.time = addLeadingZerosToTime(dateTime.time)
    }

    return dateTime
  }

  const validate = data => {
    let errors = {}

    if (data) {
      const startTime = data.start
      const endTime = data.end

      const start = parse(startTime, 'HH:mm', new Date())
      const end = parse(endTime, 'HH:mm', new Date())

      if (!data.divisionID || data.divisionID === 0) {
        errors.divisionID = 'Selecteer eerst een organisatie.'
      }
      if (!data.description || data.description === '') {
        errors.description = 'Beschrijving invullen is verplicht.'
      }
      if (start.valueOf() >= end.valueOf()) {
        errors.start = 'Start tijd moet voor eind tijd zijn.'
        errors.end = 'Start tijd moet voor eind tijd zijn.'
      }
    }

    setValidation(errors)

    return !!Object.keys(errors).length
  }

  const submitForm = e => {
    e.preventDefault()

    const form = e.currentTarget
    const formData = Object.fromEntries(new FormData(form))
    formData.dateStart = document.querySelector('#dateStart input').value
    formData.dateEnd = document.querySelector('#dateEnd input').value

    formData.start = document.querySelector('#timeStart input').value
    formData.end = document.querySelector('#timeEnd input').value

    if (validate(formData)) return false

    formData.userID = prefill.userID ? prefill.userID : userData.profile.id
    formData.projectID = selectedProject
    formData.taskID =
      selectedProject !== -1 && selectedTask && selectedTask
        ? selectedTask
        : null

    //reformat date
    formData.dateStart = format(
      parse(formData.dateStart, 'dd/MM/yyyy', new Date()),
      'yyyy-MM-dd',
    )
    if (formData.dateEnd) {
      formData.dateEnd = format(
        parse(formData.dateEnd, 'dd/MM/yyyy', new Date()),
        'yyyy-MM-dd',
      )
    }

    fetchPostJsonAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/timesheets/record`,
      method: 'PUT',
      headers: {
        Authorization: userData.bearerToken,
      },
      json: formData,
    }).then(() => {
      overlayToggleOpen(false)
      timesheetFetchNewData()
    })
  }

  useEffect(() => {
    if (prefill && prefill.project) {
      setSelectedOrg(orgPrefillId)
      setSelectedProject(prefill.project.projectID)
      setSelectedTask(prefill.taskID)
    }
  }, [prefill, orgPrefillId])

  useEffect(() => {
    if (selectedStartDay) {
      setSelectedEndDay(selectedStartDay)
    }
  }, [selectedStartDay])

  useEffect(() => {
    if (selectedOrg) {
      validate(null)
      setSelectedOrg(selectedOrg)
    }
  }, [selectedOrg])

  return (
    <form className="timesheetEntryForm" onSubmit={e => submitForm(e)}>
      <input type="hidden" name="id" value={prefill.id ? prefill.id : ''} />
      <div className="formWrapper">
        {!!validation.end ? (
          <Alert
            onClose={() => {
              validate(null)
            }}
            style={{ marginBottom: '3.2rem' }}
            severity="error"
          >
            {validation.end}
          </Alert>
        ) : null}
        <div className="columnHolder">
          <div className="column">
            <h4 className="title">Wanneer</h4>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <div className="inputs-2">
                <DateSelect
                  label={'Dag'}
                  id={'dateStart'}
                  name={'dateStart'}
                  prefill={
                    prefill.time ? prefill.time.date : filteringRange.startDate
                  }
                  onChange={setSelectedStartDay}
                  required
                />
                <TimeSelect
                  label={'Start tijd'}
                  id={'timeStart'}
                  name={'start'}
                  prefill={
                    prefill.time
                      ? timePlaceholder(prefill.time.date, prefill.time.start)
                      : null
                  }
                  error={validation.start ? !!validation.start : false}
                />
              </div>
              <div className="inputs-2">
                {prefill.id ? (
                  <DateSelect
                    label={'Record herhalen tot'}
                    id={'dateEnd'}
                    name={'dateEnd'}
                    prefill={filteringRange.endDate}
                    disabled
                    style={{ visibility: 'hidden' }}
                  />
                ) : (
                  <DateSelect
                    label={'Record herhalen tot'}
                    id={'dateEnd'}
                    name={'dateEnd'}
                    value={selectedEndDay}
                    onChange={setSelectedEndDay}
                    required
                  />
                )}
                <TimeSelect
                  id={'timeEnd'}
                  name={'end'}
                  label={'Eind tijd'}
                  prefill={
                    prefill.time
                      ? timePlaceholder(prefill.time.date, prefill.time.end)
                      : null
                  }
                  error={
                    validation.end !== undefined ? !!validation.end : false
                  }
                  helperText={
                    validation.end !== undefined ? validation.end : ''
                  }
                  required
                />
              </div>
              <div className="inputs-2">
                <SwitchMat
                  label="Afwezig"
                  name="vacation"
                  prefill={selectedProject === -1}
                  onChange={() =>
                    setSelectedProject(selectedProject === -1 ? null : -1)
                  }
                />
              </div>
            </LocalizationProvider>
          </div>
          <div className="column">
            <h4 className="title">Wat</h4>
            <SelectMat
              label="Organisatie *"
              id="organisatie"
              name={'divisionID'}
              onChange={setSelectedOrg}
              value={selectedOrg}
              items={divisionOptions}
              error={!!validation.divisionID}
            />
            {selectedProject !== -1 && (
              <SelectMat
                id="project"
                label="Project"
                name="projectID"
                items={projectOptions}
                value={selectedProject}
                onChange={setSelectedProject}
              />
            )}
            {!!selectedProject && selectedProject !== -1 && (
              <SelectMat
                id="taak"
                label="Taak"
                name="taskID"
                items={taskOptions}
                value={selectedTask}
                onChange={setSelectedTask}
              />
            )}
          </div>
        </div>
        <InputMat
          label="Beschrijving"
          id="desc"
          name={'description'}
          prefill={prefill.desc}
          error={!!validation.description}
        />
      </div>

      <Button type="submit" variant="contained">
        {props.submitText ? props.submitText : 'submit'}
      </Button>
    </form>
  )
}

export default TimesheetEntryForm
