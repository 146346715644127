import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { updateCompany } from '../crmSlice.js'
import { Item } from '../detail/Item'
import { CompanyForm } from '../forms/CompanyForm'

export function CreateCompany() {
  const history = useHistory()
  const dispatch = useDispatch()

  const onSubmit = async values => {
    await dispatch(updateCompany({ id: 0, ...values }))
    onCancel()
  }
  const onCancel = () => {
    history.goBack()
  }

  return (
    <Item elevation={3}>
      <CompanyForm onSubmit={onSubmit} onCancel={onCancel} />
    </Item>
  )
}
