import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { TextField, Button } from '@mui/material'
import { fetchPostJsonAPI } from '../../../../general/BuddyFuncs'
import { overlayToggleOpen } from '../api'

const OrganizationForm = () => {
  const [inputFieldLiveFilter, setInputFieldLiveFilter] = useState('')
  const userData = useSelector(state => state.loginReducer.data)

  const handleInputField = e => {
    const filterValue = e.currentTarget.value.replace(/[^a-zA-Z0-9]/, '')
    setInputFieldLiveFilter(filterValue)
  }

  const submitForm = e => {
    e.preventDefault()
    const form = e.currentTarget
    const formData = Object.fromEntries(new FormData(form))
    overlayToggleOpen(false)

    return fetchPostJsonAPI({
      url: `${process.env.REACT_APP_PUBLIC_URL}api/user/new-division`,
      method: 'PUT',
      headers: {
        Authorization: userData.bearerToken,
      },
      json: {
        divisionID: 0,
        name: formData.organization,
      },
    }).then(v => {
      if (!v.success) throw Error('creation failed')
      return v.record
    })
  }

  return (
    <form className="employeeForm" onSubmit={e => submitForm(e)}>
      <div style={{ width: '100%' }}>
        <div className="inputMat input">
          <TextField
            variant="outlined"
            id="organization"
            name="organization"
            label="organisatie"
            value={inputFieldLiveFilter}
            onChange={handleInputField}
            required
          />
        </div>
      </div>
      <Button variant="contained" type="submit">
        opslaan
      </Button>
    </form>
  )
}

export default OrganizationForm
