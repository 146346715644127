import { store } from '../../redux/configureStore'
import { setOptionsAction, setSelectedAction } from './typeSelectorActions'

export const typeSelectorSetSelected = selected => {
  store.dispatch(setSelectedAction(selected))
}

export const typeSelectorSetOptions = options => {
  store.dispatch(setOptionsAction(options))
}
