import React from 'react'
import DayMeter from '../dayMeter/DayMeter'
import Entry from './components/Entry'

const TimesheetDay = props => {
  const content = props.content

  const createHeader = buttons => {
    const headers = content.headers.map(header => {
      return <td key={`headers${header}`}>{header}</td>
    })

    for (let i = 0; i < buttons; i++) {
      headers.push(<td key={`headerBtn${i}`}></td>)
    }

    return headers
  }

  return (
    <div className="timesheetDay">
      <DayMeter orientation="vertical" content={content} />
      {content.entries && content.entries.length > 0 ? (
        <table className="entries">
          <thead>
            <tr>{createHeader(1)}</tr>
          </thead>
          <tbody>
            {content.entries.map(entry => {
              return (
                <Entry
                  date={content.date}
                  entry={entry}
                  id={entry.id}
                  entryBtns={props.entryBtns}
                  key={`entrieKey${entry.id}`}
                />
              )
            })}
          </tbody>
        </table>
      ) : (
        <div className="noEntries">
          <span>Er is nog geen tijd geregistreerd op deze dag.</span>
        </div>
      )}
    </div>
  )
}

export default TimesheetDay
