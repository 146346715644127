import React from 'react'
import TotalPercentage from './TotalPercentage'
import DayOverview from './DayOverview'
import { diffTimeMinutes, isToday, map } from '../../../general/BuddyFuncs'

const DayMeter = props => {
  const orientation = props.orientation ? props.orientation : 'vertical'

  const availableMinutes =
    diffTimeMinutes(props.content.startTime, props.content.endTime) -
    props.content.break

  const calcPercent = () => {
    let totalCurrent = 0
    let percent = 0

    //taly everything up
    if (props.content.entries) {
      props.content.entries.forEach(entry => {
        totalCurrent += diffTimeMinutes(entry.start, entry.end)
      })

      //map total current to a percentage
      percent = Math.ceil(map(totalCurrent, 0, availableMinutes, 0, 100))
    } else {
      percent = 0
    }

    return percent > 100 ? 100 : percent
  }

  const isError = () =>
    isToday(props.content.date) || calcPercent() >= 100 ? '' : 'error'

  return (
    <div className={`dayMeter ${orientation} ${isError()}`}>
      <TotalPercentage percent={calcPercent()} />
      <DayOverview
        content={props.content.entries}
        totalMinutes={availableMinutes}
        date={props.content.date}
        orientation={orientation}
      />
    </div>
  )
}

export default DayMeter
