import React from 'react'
import { diffTimeMinutes, map, isToday } from '../../../general/BuddyFuncs'

const DayOverview = props => {
  const calcPercent = item => {
    return map(
      diffTimeMinutes(item.start, item.end),
      0,
      props.totalMinutes,
      0,
      100,
    )
  }

  const createStyle = percent => {
    //set property
    if (props.orientation === 'vertical') {
      return { height: `${percent}%` }
    }

    return { width: `${percent}%` }
  }

  return (
    <div className="dayOverview">
      {props.content
        ? props.content.map((item, index) => {
            const percent = calcPercent(item)
            return (
              <div
                className="filled"
                key={`dayOverviewItem${item.start}${item.end}${index}`}
                style={createStyle(percent)}
              ></div>
            )
          })
        : null}
      {isToday(props.date) ? <div className="toDo"></div> : null}
    </div>
  )
}

export default DayOverview
