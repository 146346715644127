import { writeDate } from '../../../general/BuddyFuncs'
import { store } from '../../redux/configureStore'
import { resetAction, fetchNewEntriesAction } from './timesheetActions'

export const timesheetFetchNewData = () => {
  store.dispatch(resetAction())
  timesheetCheckForEntries()
}

export const timesheetCheckForEntries = () => {
  const state = store.getState()
  const timesheetReducer = state.timesheetReducer

  if (!timesheetReducer.fetched && !timesheetReducer.fetching) {
    //get selected user/profile
    const selectedtype = state.typeSelectorReducer.selected
    const fromTo = state.timesheetFilterReducer.fromTo

    //set input data
    const input = new FormData()

    input.append(
      'date_start',
      fromTo.startDate ? fromTo.startDate : writeDate(new Date(), '-'),
    )
    input.append(
      'date_end',
      fromTo.endDate ? fromTo.endDate : writeDate(new Date(), '-'),
    )

    //set project_id or users_id
    if (selectedtype.index) {
      let name = false

      switch (selectedtype.type) {
        case 'user':
          name = 'users_id'
          break
        case 'project':
          name = 'project_id'
          input.delete('date_start')
          input.delete('date_end')

          break
        default:
          break
      }
      if (name) {
        input.append(name, selectedtype.index)
      }
    }

    //preform fetch
    store.dispatch(fetchNewEntriesAction(input))
  }
}
