import React, { useState } from 'react'
import dayjs from 'dayjs'

import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import TextField from '@mui/material/TextField'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import Alert from '@mui/material/Alert'

const TimeSelect = props => {
  const [date, setDate] = useState(null)

  const handleDateChange = newDate => {
    setDate(newDate)
  }

  function roundToNearest5(date = new Date()) {
    const minutes = 5
    const ms = 1000 * 60 * minutes

    // 👇️ replace Math.round with Math.ceil to always round UP
    return new Date(Math.round(date.getTime() / ms) * ms)
  }

  return (
    <div className="timeSelect">
      <div id={props.id} className="input">
        <TimePicker
          ampm={false}
          inputVariant="outlined"
          showTodayButton
          todayLabel="now"
          label={props.label}
          name={props.name}
          value={
            date
              ? date
              : props.prefill
              ? new Date(`${props.prefill.date}T${props.prefill.time}`)
              : roundToNearest5()
          }
          error={props.error !== undefined ? props.error : false}
          mintime={props.minTime}
          maxtime={props.maxTime}
          onChange={handleDateChange}
          required={props.required !== null && props.required !== undefined}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
          keyboardIcon={<AccessTimeIcon />}
          renderInput={props => <TextField {...props} />}
        />
      </div>
    </div>
  )
}

export default TimeSelect
