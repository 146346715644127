import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import {
  createCompanyLink,
  fetchCompanyById,
  selectCompany,
  createContactLink,
  deleteCompanyLink,
  deleteContactLink,
} from '../crmSlice'
import { useParams, Link, useHistory } from 'react-router-dom'

import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add'
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined'

import { useDispatch, useSelector } from 'react-redux'
import { Links } from './Links'
import { CompanyCard } from './CompanyCard'
import { ContactCard } from './ContactCard'
import { useTranslation } from 'react-i18next'

export function CompanyDetail() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { companyId } = useParams()
  const history = useHistory()

  const [loaded, setLoaded] = useState(false)

  const company = useSelector(selectCompany)

  const organisations = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.organisations || {},
  )
  const companyTypes = useSelector(
    state => state.overlayReducer.selectOptions.data.crm?.companyTypes || {},
  )
  const functions = useSelector(
    state =>
      state.overlayReducer.selectOptions.data.crm?.contactFunctions || {},
  )

  const onAddCompanyLink = async values => {
    await dispatch(
      createCompanyLink({ id: 0, crm_company_id: companyId, ...values }),
    )
    return dispatch(fetchCompanyById(companyId))
  }

  const onDeleteCompanyLink = async link => {
    await dispatch(deleteCompanyLink(link.id))
    return dispatch(fetchCompanyById(companyId))
  }

  const onAddContactLink = async values => {
    await dispatch(
      createContactLink({ id: 0, crm_company_id: companyId, ...values }),
    )
    return dispatch(fetchCompanyById(companyId))
  }

  const onDeleteContactLink = async link => {
    await dispatch(deleteContactLink(link.id))
    return dispatch(fetchCompanyById(companyId))
  }

  useEffect(() => {
    // load company only once
    if (!loaded) {
      setLoaded(true)
      dispatch(fetchCompanyById(companyId))
    }
  }, [companyId, dispatch, loaded, setLoaded])

  if (!company) return null

  return (
    <Grid key={'container'} container columnSpacing={4} rowSpacing={4}>
      <Grid item xs={12} key={`goback`}>
        <Button
          key={'goback-btn'}
          style={{ fontSize: '1rem' }}
          onClick={e => {
            history.goBack()
          }}
        >
          <ArrowBackIosNewOutlinedIcon fontSize="inherit" /> &nbsp; Terug naar
          overzicht
        </Button>
      </Grid>
      <Grid item xs={8} key={`company-card`}>
        <CompanyCard key={`company-card-component`} />
      </Grid>
      <Grid item xs={4} key={`company-info`}>
        <Links
          key={'company-info-links'}
          onAdd={onAddCompanyLink}
          onDelete={onDeleteCompanyLink}
          links={company.orgs.map(org => ({
            organisation: org.organisatie,
            type: org.type,
            id: org.crm_lnk_org_id,
          }))}
          addFields={[
            {
              key: 'company-lnk_org_name',
              name: 'lnk_org_name',
              label: 'Organisatie naam',
              options: organisations,
            },
            {
              key: 'company-lnk_type',
              name: 'lnk_type',
              label: 'Type bedrijf',
              options: companyTypes,
            },
          ]}
        />
      </Grid>

      {company &&
        company.contacts.map(contact => (
          <>
            <Grid item xs={8} key={`${contact.id}-card`}>
              <ContactCard contact={contact} key={contact.id} />
            </Grid>
            <Grid item xs={4} key={`${contact.id}-links`}>
              <Links
                key={`${contact.id}-links-ad`}
                onAdd={values =>
                  onAddContactLink({ crm_contacts_id: contact.id, ...values })
                }
                onDelete={onDeleteContactLink}
                links={contact.orgs.map(org => ({
                  organisation: org.organisatie,
                  type: org.functie,
                  id: org.crm_lnk_contacts_id,
                }))}
                addFields={[
                  {
                    key: 'contact-lnk_org_name',
                    name: 'lnk_org_name',
                    label: 'Organisatie naam',
                    options: organisations,
                  },
                  {
                    key: 'contact-lnk_function',
                    name: 'lnk_function',
                    label: 'Functie',
                    options: functions,
                  },
                ]}
              />
            </Grid>
          </>
        ))}

      <Grid key={'add-contact'} item xs={8} style={{ textAlign: 'right' }}>
        <Button
          component={Link}
          size="large"
          color="primary"
          variant="contained"
          to={`/crm/companies/${companyId}/add-contact`}
        >
          <AddIcon />
          &nbsp;
          {t('general.contactToevoegen')}
        </Button>
      </Grid>
    </Grid>
  )
}
