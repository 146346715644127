import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Item } from './Item'
import { CompanyForm } from '../forms/CompanyForm'
import { CompanyInfo } from './CompanyInfo'
import {
  selectCompany,
  updateCompany,
  fetchCompanyById,
  deleteCompanyById,
} from '../crmSlice'
import { useHistory } from 'react-router-dom'

export function CompanyCard() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [edit, setEdit] = useState(false)

  const company = useSelector(selectCompany)

  const onDelete = async () => {
    await dispatch(deleteCompanyById(company.crm_bedrijf_id))
    history.goBack()
  }

  const onSubmit = async data => {
    await dispatch(updateCompany({ id: data.crm_bedrijf_id, ...data }))
    await dispatch(fetchCompanyById(data.crm_bedrijf_id))
    setEdit(false)
  }

  if (!company) return null

  return (
    <Item elevation={3}>
      {edit ? (
        <CompanyForm
          key={'form-' + company}
          company={company}
          onCancel={() => setEdit(false)}
          onSubmit={onSubmit}
        />
      ) : (
        <CompanyInfo
          key={'info-' + company}
          company={company}
          onEditClick={() => setEdit(true)}
          onCancel={() => setEdit(false)}
          onDelete={onDelete}
        />
      )}
    </Item>
  )
}
